import { BottomNavigation, BottomNavigationAction, useTheme } from "@mui/material";
import { menu as fullMenu, useAppRoute } from "./AppRoute";

const menu = [
    { ...fullMenu.find(x => x?.id === 'desktop-menu'), title: 'Главная' },
    fullMenu.find(x => x?.id === 'bill-menu')!,
    fullMenu.find(x => x?.id === 'measure-menu')!,
    fullMenu.find(x => x?.id === 'providedService-menu')!,
    fullMenu.find(x => x?.id === 'request-menu')!
];

export default function BottomBar() {
    const theme = useTheme();
    const [route, path, current] = useAppRoute(menu);

    return <BottomNavigation
        showLabels
        value={menu.length - current - 1}
        sx={{
            height: 'auto',
            pb: 'env(safe-area-inset-bottom)',
            '& > .MuiBottomNavigationAction-root': {
                p: 1,
            },
            '& > .MuiBottomNavigationAction-root:not(.Mui-selected) path': {
                fill: theme.palette.text.primary
            },
            '& .MuiBottomNavigationAction-label,& .MuiBottomNavigationAction-label.Mui-selected': {
                fontSize: 10,
                fontWeight: 500
            }
        }}
        onChange={(e, indx) => route.setPath(menu[indx].path!.replace(/\/\*$/, '').replace(/\/:.*$/, ''))}
    >
        {menu.map(({ title, ...props }) => <BottomNavigationAction label={title} {...props} />)}
    </BottomNavigation>;
}