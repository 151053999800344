import { AccessTime, AddRounded, ChevronRight, NorthEast, SouthEast } from "@mui/icons-material";
import { Box, Button, Card, CardHeader, Theme, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import moment from "moment";
import { useState } from "react";
import { columns } from ".";
import route from "../../Router";
import NavLink, { NavButton } from "../../components/NavLink";
import AccentPaper from "../../components/Paper";
import DataTable, { BaseDataTable, ColumnData, Renderer } from "../../components/data/DataTable";
import { Charges } from "../../icons";
import { useIsMobile, useRemoteData } from "../../models/Hooks";
import { Payment } from "../../models/Types";
import { numberFormat } from "../../utils";
import { paymentParse } from "./PaymentForm";


const epCollumns = [
    { name: 'Sum', variant: 'h3' },
    { name: 'HouseId', variant: 'caption' },
    { name: 'CreateDate', variant: 'h4' }
].map(({ name, variant, ...n }) => {
    const col = { ...columns.find(x => x.name === name) } as ColumnData<Payment>;
    if (col.routerLink) {
        col.routerParams = p => {
            p.push(['page', 'payment']);
            return p;
        }
    }
    const renderer = col.renderer || ((x: any, o: Payment, th: Theme, ta: BaseDataTable<Payment>, d?: Renderer<Payment>) => d ? d(x, o, th, ta, d) : x);
    col.renderer = (x, o, th, ta, d) => <Typography variant={variant as Variant}>{renderer(x, o, th, ta, d)}</Typography>
    return col;
});

function DesktopWidget() {
    const [aggs, setAggs] = useState<{ cur: number, prev: number, diff: number }>();
    return <Card sx={{ flex: 1 }}>
        <CardHeader
            titleTypographyProps={{ display: 'flex', variant: 'h1' }}
            title={<>
                Поступления платежей
                <NavLink variant="caption" route={[['page', 'payment'], ['id']]}>Все <ChevronRight fontSize="small" /></NavLink>
                <NavButton size="small" sx={{ ml: 'auto' }} route={[['page', 'payment'], ['id', 'new']]} variant="contained" color="info">
                    <AddRounded />
                </NavButton>
            </>} />
        <div>
            <Box sx={{ bgcolor: 'info.main', display: 'flex', py: 2, borderRadius: .8, alignItems: 'flex-end' }}>
                <Box sx={{ px: 2.5 }}>
                    <Typography variant="h1" fontSize={32}>{numberFormat(aggs?.cur, '₽')}</Typography>
                    <Typography variant="caption">поступлений за месяц</Typography>
                </Box>
                <Box sx={{ borderLeft: '1px solid #C6E2FF', px: 2.5 }}>
                    <Typography variant="h2" color="primary" display="flex">
                        {aggs?.diff ? aggs?.diff > 0 ? '+' : '-' : null}
                        {numberFormat(aggs?.diff, '₽')}
                        {aggs?.diff ? aggs?.diff > 0 ? <NorthEast fontSize="small" /> : <SouthEast fontSize="small" /> : null}
                    </Typography>
                    <Typography variant="caption">по сравнению с прошлым периодом</Typography>
                </Box>
            </Box>
        </div>
        <DataTable<Payment> typeId="Payment"
            columns={epCollumns}
            dataParser={paymentParse}
            onAfterLoad={(d, r) => {
                const cur = d.map(x => x.Sum).reduce((a, b) => a + b, 0);
                setAggs({ cur, prev: r.result.Prev, diff: cur - r.result.Prev });
            }}
            cmd={`PaymentLastMonth`}
            showFilter={false}
            onSelectionChange={x => x[0] && route.setState([['page', 'payment'], ['id', x[0]]])} />
    </Card>;
}

function MobileWidget() {
    const [bill] = useRemoteData<{
        Amount: number
        LastBill: Date
    }>('BillAmount');

    return <AccentPaper sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'space-between' }}>
        <Box display="flex" gap={1}>
            <Box>
                <Typography variant="h1">
                    {bill?.Amount} ₽
                </Typography>
                <Typography component="div" variant="caption">к оплате по вашему дому</Typography>
            </Box>
            {Charges}
        </Box>
        {bill ? <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <Button variant="contained" color="info" startIcon={<AccessTime />} sx={{ fontSize: 8, p: 1 }}>
                Последнее начисление<br />
                {moment(bill?.LastBill).calendar()}
            </Button>
            <Button variant="contained">Оплатить</Button>
        </Box > : null}
    </AccentPaper>
}

export function PaymentWidget() {
    const ism = useIsMobile();
    return ism ? <MobileWidget /> : <DesktopWidget />;
}