import { ChevronRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import NavLink, { NavButton } from "../../../components/NavLink";
import { Services } from "../../../icons";
import { useIsMobile, useRemoteList } from "../../../models/Hooks";
import { Service } from "../../../models/Types";

const styles = {
    mobileListItem: {

    }
};

function MobileWidget() {
    const [data] = useRemoteList<Service>(`ServiceList?query=${JSON.stringify({ Sort: { Property: 'Count', Direction: 1 } })}`);
    const [t, st] = useState(0);
    const [all, setAll] = useState(false);

    const cats = useMemo(() => data ? ['Все', ...data.reduce((a, b) => {
        b.Categories?.filter(x => a.indexOf(x) < 0).forEach(x => a.push(x));

        return a;
    }, [] as string[])] : null, [data]);

    function buildCategoryButton(c: string, i: number) {
        return <Button variant="text" onClick={() => st(i)} disabled={i === t}><Typography variant="h3">{c}</Typography></Button>;
    }

    const filtered = data?.filter(x => !t || !cats || x.Categories?.indexOf(cats[t]) >= 0);
    const more = filtered && !all ? filtered.length - 5 : 0;

    return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h2" sx={{
            "&,&>*": {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }
        }} >
            Услуги
            <NavLink variant="caption" route={[['page', 'service'], ['id']]}>Все <ChevronRight fontSize="small" /></NavLink>
        </Typography>
        {cats && cats.length > 1 ? <Box sx={{
            overflowX: 'auto', display: 'inline-flex', "&>.MuiButton-root": {
                flex: 'none',
                display: 'flex'
            }
        }
        } > {cats?.map(buildCategoryButton)}</Box> : null
        }
        <Box sx={{
            display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: 1,
            "&>.MuiButton-root": {
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1,
                p: 2,
                flex: 1,
                "&>.MuiButton-startIcon": {
                    ml: -.25
                }
            },
            "&>.MuiButton-contained path": {
                fill: '#fff'
            }
        }} >
            {filtered?.filter(all ? () => true : ((x, i) => i < 5))
                .map(x => <NavButton variant="contained" startIcon={Services} route={[['page', 'service'], ['id', 'new'], ['service', x.Id]]}>
                    <Typography variant="h4">{x.Name}</Typography>
                </NavButton>)}
            {(more || all) > 0 ? <Button variant="outlined" startIcon={Services} onClick={() => setAll(!all)}>
                <Typography variant="h4">{all ? 'Свернуть' : <>Еще {more} услуг{more % 10 === 1 ? 'а' : more % 10 < 5 ? 'а' : null}</>}</Typography>
            </Button> : null}
        </Box>
    </Box>;
}

function DesktopWidget() {
    return <></>;
}

export function ServiceWidget() {
    const ism = useIsMobile();
    return ism ? <MobileWidget /> : <DesktopWidget />;
}