import Proxy from "./Proxy";
import Observer from "./Observer";
import { getTenant, getTenantId, resolveTenant, setTenant } from "./Tenant";
import { User } from "./Types";

var user: User;
var init = false;

const observer = new Observer();

const fireUserUpdate = () => observer.fire('update', [user]);

export const getUser = () => user;

export const isAuthentificated = () => (user && user.IsAuthenticated);

export const isSuper = () => (isAuthentificated() && user.Name === 'SYSTEM');

const _isOwner = () => user.Tenants.find(x => x.Id === getTenantId())?.OwnerId === user?.Id;
export const isOwner = () => (isAuthentificated() && _isOwner());

export const hasRoles = () => (isAuthentificated() && (_isOwner() || !!getTenant()?.Roles?.length));

export const isInit = () => init;

export const fetchUser = () => Proxy.get('CurrentUser')
    .then(x => {
        if (x.success) {
            init = true;

            user = x.result;

            fireUserUpdate();

            if (isAuthentificated()) {
                let t = getTenant();
                if (!t || (window as any).TenantInfo || !user.Tenants.find(x => x.Id == t!.Id)) {
                    let accId = resolveTenant();
                    if (accId) {
                        setTenantId(accId) || setTenantId();
                    } else {
                        setTenant(user.Tenants.sort((a, b) => a.Id < b.Id ? 1 : -1)[0]);
                    }
                }
            }
        }

        return user;
    });

export const generateToken = () => Proxy.get('GenerarateAuthToken');

export const setTenantId = function (id?: number) {
    var acc = id ? getUser().Tenants.find(x => x.Id == id) : null;
    if (!!acc == !!id) {
        setTenant(acc);
        return true;
    }
    else {
        return false;
    }
}

export const listenUser = (fn: Function): (() => void) => observer.listen('update', fn);

export const login = (type: string, config: any) => Proxy.post('login' + type, config)
    .then(x => {
        if (x.success && x.result.Succeeded) {
            return fetchUser();
        }

        return x;
    });

export const register = (email: string, password: string, confirmPassword: string) =>
    Proxy.post('register', {
        email,
        password,
        confirmPassword
    })
        .then(x => {
            if (x.success && x.result.Succeeded) {
                return fetchUser();
            }

            return x;
        });

export const logout = () => {
    return Proxy.post('logout')
        .then(x => {
            fetchUser();
            return x;
        });
}

fetchUser();