import { ArrowRight, ChevronRight } from "@mui/icons-material";
import { Box, Button, Card, CardHeader, Typography } from "@mui/material";
import moment from "moment";
import { useMemo, useState } from "react";
import NavLink from "../../components/NavLink";
import { useIsMobile, useRemoteList } from "../../models/Hooks";
import { Post } from "../../models/Types";

export function PostWidget() {
    const ism = useIsMobile();
    const [data] = useRemoteList<Post>(`PostList?query=${JSON.stringify({ Sort: { Property: 'CreateDate', Direction: 1 }, Count: 10, LazyLoad: true })}`);
    const [t, st] = useState(0);

    const tags = useMemo(() => data?.reduce((a, b) => {
        b.HashTags?.filter(x => a.indexOf(x) < 0).forEach(x => a.push(x));

        return a;
    }, [] as string[]), [data])

    const carousel = <Box sx={{ overflow: 'auto hidden', mt: -2.5, mx: ism ? -2 : 0 }}>
        <table cellSpacing={20}>
            <tbody>
                <tr>
                    {data?.filter(x => !t || !tags || x.HashTags?.indexOf(tags[t - 1]) >= 0)
                        .map(x => <td>
                            <NavLink key={x.Id} route={[['page', 'news'], ['id', x.Id]]} sx={{
                                width: 400, border: '1px solid #D2D8E8', borderRadius: 1, p: 4, height: '12em', textDecoration: 'none', color: 'inherit',
                                display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'hidden'
                            }}>
                                <Typography fontSize={18} fontWeight={400} height="4.5em" overflow="hidden" textOverflow="ellipsis">{x.Name}</Typography>
                                <Box display="flex" justifyContent="space-between" >
                                    <Typography variant="caption">{moment(x.CreateDate).calendar()}</Typography>
                                    <Button startIcon={<ArrowRight />} variant="contained" color="info" sx={{
                                        minWidth: 0,
                                        p: 0,
                                        '& > .MuiButton-icon': {
                                            margin: 0
                                        }
                                    }} />
                                </Box>
                            </NavLink>
                        </td>)}
                </tr>
            </tbody>
        </table>
    </Box>;

    if (ism) {
        return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h2" sx={{
                "&,&>*": {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }
            }} >
                Новости
                <NavLink variant="caption" route={[['page', 'news'], ['id']]}>Все <ChevronRight fontSize="small" /></NavLink>
            </Typography>
            {carousel}
        </Box>;
    }

    return <Card sx={{ flex: 3 }} elevation={ism ? 0 : undefined}>
        <CardHeader title={<>Новости <NavLink route={[['page', 'news'], ['id']]}>Все <ChevronRight /></NavLink></>} />
        {carousel}
    </Card>;
}