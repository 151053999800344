import { WarningAmberRounded } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeaderProps, InputAdornment, CardHeader as MuiCardHeader, Paper, Typography, useTheme } from "@mui/material";
import { TextInput } from "../../components/form/TextInput";
import { Information } from "../../icons";
import { useCachedStore, useIsMobile, useRemoteData } from "../../models/Hooks";
import { isMobile } from "../../utils";
import { meterTariffStore } from "../measure/MeterTariffCombo";

function CardHeader(props: CardHeaderProps) {
    return <MuiCardHeader {...props}
        titleTypographyProps={{ variant: isMobile() ? 'h2' : 'h1', ...props?.titleTypographyProps }}
        subheaderTypographyProps={{ variant: isMobile() ? 'caption' : 'body2', ...props?.subheaderTypographyProps }}
    />;
}

interface MeasureWidgetData {
    Measures: {
        Cur: number
        Prev?: number
        MeterTariffId: number
    }[]

    Meters: {
        All: number,
        Overdue: number
    }[]
}

function DesktopWidget() {
    const [data] = useRemoteData<MeasureWidgetData>('MeasureAnalytic');
    const [meters] = useCachedStore(meterTariffStore);

    if (data && !data.Measures) {
        return <></>;
    }

    return <Card sx={{ flex: 1 }}>
        <CardHeader title="Показания счетчиков" subheader="Принимаются с 20 по 25 число каждого месяца" />
        <CardContent sx={{ gap: 2, display: 'flex', flexDirection: 'column' }} >
            {meters ? data?.Measures.map(x => <TextInput key={x.MeterTariffId}
                name={x.MeterTariffId}
                onChange={e => { }}
                label={meters.find(t => t.Id == x.MeterTariffId)?.Value || x.MeterTariffId.toString()}
                value={x.Cur}
                inputProps={{
                    type: 'number',
                    min: x.Prev,
                    endAdornment: <InputAdornment position="end">{x.Prev}</InputAdornment>
                }} />) : null}
            <Box>
                <Button variant="contained" sx={{ ml: 'auto' }}>Сохранить</Button>
            </Box>
        </CardContent>
    </Card>;
}

function MobileWidget() {
    const theme = useTheme();
    return <Paper elevation={2} sx={{ gap: 2, p: 2, display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'space-between' }}>
        <Box display="flex" gap={1} sx={{ "&>svg": { width: 50 } }} >
            <Box>
                <Typography variant="h2">Счетчики</Typography>
                <Typography component="div" variant="caption">Принимаются с 20 по 25 число каждого месяца</Typography>
            </Box>
            {Information}
        </Box>
        <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <Button variant="contained" startIcon={<WarningAmberRounded />} sx={{
                fontSize: 8,
                p: 1,
                textAlign: 'left',
                background: '#FFEAE6',
                color: theme.palette.error.main
            }}>
                Последний день внесения показаний
            </Button>
            <Button variant="contained">Передать показания</Button>
        </Box>
    </Paper>;
}

export function MeasuresWidget() {
    const ism = useIsMobile();
    return ism ? <MobileWidget /> : <DesktopWidget />;
}