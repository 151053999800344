import { ActivityType, LocationType, OperationType, PeriodicServiceType, RequestStatus } from "./Enums"

export interface IEntity<TKey = number> {
    Id: TKey
}

export interface IHierarchicalEntity extends IEntity {
    ParentId?: number

    // dto
    Children: IHierarchicalEntity[]
}

export interface ISuggest<TValue = string, TId = number, TObj = any> {
    Id: TId,
    Value: TValue,
    Obj?: TObj
}

export interface Exception {
    message?: string
    stackTrace?: string
    success?: boolean
    type?: string
}

export class BaseEntity<TKey = number> implements IEntity<TKey> {
    Id!: TKey
    $type?: string
}

type NamedEntity = {
    Name: string
    GroupId?: number
} & BaseEntity

export class IdName extends BaseEntity {
    Name!: string
};

export class BaseHierarchicalEntity extends BaseEntity implements IHierarchicalEntity {
    ParentId?: number

    // dto
    Children!: IHierarchicalEntity[]
}

export type TenantEntity = {
    TenantId: number
} & BaseEntity;

export class Tenant extends BaseEntity {
    Code?: string
    Name!: string
    Theme?: string
    OwnerId?: number
    LoginImage?: string
    PrimaryColor?: string
    Logo?: string

    Houses?: {
        Balance: number
        Street: string
        Number: string
    }[]
};

export type User = {
    UserName: string
    Name?: string
    Password: string
    IsAuthenticated: boolean
    Tenants: Tenant[]
    Roles: string[]
    Permissions?: string[]
} & BaseEntity;

export type Role = {
    Name: string
    PermissionKeys: string[]
} & BaseEntity<string>;
export type Group = {

} & NamedEntity


export type Contact = {
    Type?: string,
    Value?: string,
    Description?: string
}

export class Person extends BaseEntity {
    Name?: string
    Surname?: string
    Patronomic?: string
    Description?: string
    CreateDate?: Date
    UserId?: number
    Gender?: boolean

    EmailNames: string[] = []
    Emails: string[] = []
    LinkNames: string[] = []
    Links: string[] = []
    PhoneNames: string[] = []
    Phones: string[] = []

    // Client
    StatusId?: number

    // Employee
    PositionIds?: number[]

    // Employee
    HouseIds?: number[]

    get fio() {
        return [this.Surname, this.Name, this.Patronomic].filter(x => x).join(' ');
    }

    setContacts(contacts: Contact[]) {
        this.EmailNames = []
        this.Emails = []
        this.LinkNames = []
        this.Links = []
        this.PhoneNames = []
        this.Phones = []

        contacts?.forEach(x => {
            switch (x.Type) {
                case 'Email':
                case 'Phone':
                case 'Link':
                    this.AddContact(x.Type, x);
                    break;

                default:
                    debugger;
                    break;
            }
        });
    }

    getContacts() {
        const contacts: Contact[] = [];
        this.Phones?.forEach((p, i) => contacts.push({
            Type: 'Phone',
            Value: p,
            Description: this.PhoneNames && this.PhoneNames[i]
        }));

        this.Emails?.forEach((p, i) => contacts.push({
            Type: 'Email',
            Value: p,
            Description: this.EmailNames && this.EmailNames[i]
        }));

        this.Links?.forEach((p, i) => contacts.push({
            Type: 'Link',
            Value: p,
            Description: this.LinkNames && this.LinkNames[i]
        }));

        return contacts;
    }

    private AddContact(type: string, contact: Contact) {
        (this as any)[type + 's'].push(contact.Value);
        (this as any)[type + 'Names'].push(contact.Description);
    }
}

export class Currency extends BaseEntity {
    Name!: string
    MainCurrencyRate?: number
}

export class ExchangeOperation extends BaseEntity {
    PersonId!: number
    CurrencyId!: number
    /// <summary>
    /// - � <see cref="RefOperationId"/> == null ���������� Invoice
    /// - � <see cref="RefOperationId"/> != null ������� Refund
    /// + � <see cref="RefOperationId"/> == null ���������� Enrollment
    /// + � <see cref="RefOperationId"/> != null ������ �� ���������� Payment
    /// </summary>
    Amount!: number
    Ts!: Date
    ExpDate?: Date
    RefOperationId?: number
    Description?: string

    RefOperations?: ExchangeOperation[]

    static parse(obj: any) {
        var result = Object.assign(new ExchangeOperation(), obj);
        if (obj.ts && !(obj.ts instanceof Date)) {
            result.ts = new Date(obj.ts);
        }

        if (obj.refOperations) {
            result.refOperations = obj.refOperations.map(ExchangeOperation.parse);
        }

        return result;
    }

    get type() {
        if (this.Amount > 0) {
            return this.RefOperationId ? OperationType.Payment : OperationType.Enrollment;
        } else {
            return this.RefOperationId ? OperationType.Refund : OperationType.Invoice;
        }
    }

    set type(type: OperationType) {
        this.Amount = type == OperationType.Refund || type == OperationType.Invoice ? -Math.abs(this.Amount) : Math.abs(this.Amount);
    }
}

export class Position extends BaseEntity {
    Name!: string
    SuperAccess!: boolean

    constructor() {
        super();

        this.Keys = {};
    }

    Keys: {
        [key: string]: number
    }
}

export class House extends BaseEntity {
    OwnerId?: number
    LocationId?: number
    Street?: string
    Number?: string
    Balance!: number
    Area?: number
    Residents?: number

    PeriodicServices!: number[]
}

export interface IHasAddress {
    Address?: string
    SuggestAddress?: string
    Lat?: number
    Lon?: number
}

export class Location extends BaseEntity implements IHasAddress {
    Name!: string
    Type?: LocationType
    Description?: string
    Address?: string
    Lat?: number
    Lon?: number
    SuggestAddress?: string
}

export class Account extends BaseEntity {
    CurrencyId?: number
    PersonId?: number
    Amount?: number

    MainCurrencyRate?: number
    Currency!: string
}

export type Tariff = {
    Name: string
    Price: number
    Norm: number
    Unit: string
    StartDate: Date
} & TenantEntity;

export type Meter = {
    HouseId: number
    Number: string
    Name?: string
    CheckDate: Date
    NextCheck: Date

    Tariffs: number[]
} & BaseEntity;

export type MeterTariff = {
    MeterId: number
    TariffId: number

    Tariff?: string
    HouseId?: number
} & TenantEntity;

export type Measure = {
    CreateDate: Date
    ReadDate: Date
    MeterTariffId: number
    SenderId: number
    Value: number

    HouseId?: number
} & BaseEntity;

export type EntryPass = {
    Expire?: Date
    HouseId: number
    PersonId?: number
    Name?: string
    Color?: string
    CarModel?: string
    CarNumber?: string
} & TenantEntity;

export type Bill = {
    CreateDate: Date
    PeriodNum: number
    Amount: number
    HouseId?: number
    PaymentId?: number

    MeasureBills?: MeasureBillDto[]
    ServiceBills?: ServiceBill[]
    PeriodicServices?: PeriodicServiceBill[]
} & BaseEntity;

export type MeasureBillDto = {
    CreateDate: Date
    MeasureId: number
    TariffId: number
    Value: number
    ValueDiff: number
    Price: number
    Sum: number
} & BaseEntity;

export type MeasureBill = {
    MeasureId: number
} & BaseEntity & MeasureBillDto;

export type ServiceBill = {
    CreateDate: Date
    ServiceId: number
    Count: number
    Price: number
    Sum: number
} & BaseEntity;

export type PeriodicServiceBill = {
    PeriodicServiceId: number
    Value: number
    Price: number
    Sum: number
} & BaseEntity;

export type Payment = {
    CreateDate: Date
    HouseId: number
    Sum: number
    PayerId?: number
    BillId?: number
} & BaseEntity;

export type Service = {
    Name: string
    Price: number
    Unit: string
    Icon?: string
    Categories: string[]
} & TenantEntity;

export type ProvidedService = {
    ServiceId: number
    Count: number
    Sum: number
    CreateDate: Date

    HouseIds: number[]
} & TenantEntity;

export type PeriodicService = {
    Name: string
    Price: number
    Type: PeriodicServiceType
} & TenantEntity;

export type HousePeriodicService = {
    PeriodicServiceId: number
    HouseId: number
    Value: number
} & BaseEntity;

export type Request = {
    CreateDate: Date
    Name: string
    Description: string
    ServiceIds: number[]
    HouseIds: number[]
    ApplicantId: number
    Status?: RequestStatus

    Activities?: RequestActivity[]
} & BaseEntity;

export type RequestActivity = {
    Description: string
    CreateDate: Date
    RequestId: number
    Type: ActivityType
    UserId: number
} & BaseEntity;


export type Post = {
    Name: string
    Body: string
    HashTags: string[]
    CreateDate: Date
} & TenantEntity;