import Proxy from "../Proxy";

async function initServiceWorker() {
    let swRegistration = await navigator.serviceWorker.register('/webpush-sw.js')
    let pushManager = swRegistration.pushManager;

    if (pushManager) {
        let permissionState = await pushManager.permissionState({ userVisibleOnly: true });
        switch (permissionState) {
            case 'granted':
                await pushManager.getSubscription();
                break;
        }
    }
}

export async function webPushSubscribe() {
    let swRegistration = await navigator.serviceWorker.getRegistration();
    let pushManager = swRegistration?.pushManager;
    if (pushManager) {
        let subscriptionOptions = {
            userVisibleOnly: true,
            applicationServerKey: 'BJYBCn28xKk8V80jImQkbCPAtydbTrmKy8gHRvCpvN2HwV--gZsPoobGrdp_msCyzclUjl48x_GkUM7TK_7WgJA'
        };


        Proxy.post('WebPushAddToken', await pushManager.subscribe(subscriptionOptions));
    }
}

function testSend() {
    const title = "Push title";
    const options = {
        body: "Additional text with some description",
        icon: "https://andreinwald.github.io/webpush-ios-example/images/push_icon.jpg",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Orange_tabby_cat_sitting_on_fallen_leaves-Hisashi-01A.jpg/1920px-Orange_tabby_cat_sitting_on_fallen_leaves-Hisashi-01A.jpg",
        data: {
            "url": "https://andreinwald.github.io/webpush-ios-example/?page=success",
            "message_id": "your_internal_unique_message_id_for_tracking"
        },
    };
    navigator.serviceWorker.ready.then(async function (serviceWorker) {
        await serviceWorker.showNotification(title, options);
    });
}

if (navigator.serviceWorker) {
    initServiceWorker();
}