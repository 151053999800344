import { KeyboardArrowRight } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeaderProps, Checkbox, FormControlLabel, FormGroup, CardHeader as MuiCardHeader, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import NavLink from "../../components/NavLink";
import AccentPaper from "../../components/Paper";
import { useIsMobile, useRemoteList, useTenant } from "../../models/Hooks";
import { getUser, hasRoles } from "../../models/Identity";
import { Meter } from "../../models/Types";
import TenantSelectorComponent from "../../nav/TenantSelector";
import { isMobile } from "../../utils";
import { BillWidget } from "../bill/BillWidget";
import { EntryPassWidget } from "../entrypass/EntryPassWidget";
import { MeasuresWidget } from "../measure/MeasuresWidget";
import { PaymentWidget } from "../payment/PaymentWidget";
import { PostWidget } from "../post/PostWidget";
import { ServiceWidget } from "../project/service/ServiceWidget";
import { RequestWidget } from "../request/RequestWidget";

const styles = {
    desktop: {
        inhabitant: {
            '& > *:nth-child(1)': { gridArea: '1 / 1 / 2 / 3', minHeight: 450 },
            '& > *:nth-child(2)': { gridArea: '1 / 3 / 2 / 4', minHeight: 450 },
            '& > *:nth-child(3)': { gridArea: '2 / 1 / 3 / 2', minHeight: 300 },
            '& > *:nth-child(4)': { gridArea: '2 / 2 / 3 / 3', minHeight: 300 },
            '& > *:nth-child(5)': { gridArea: '2 / 3 / 3 / 4', minHeight: 300 },
            '& > *:nth-child(6)': { gridArea: '3 / 1 / 4 / 4', minHeight: 330 },
        },
        employee: {
            '& > *:nth-child(1)': { gridArea: '1 / 1 / 2 / 3', minHeight: 450 },
            '& > *:nth-child(2)': { gridArea: '1 / 3 / 3 / 4' },
            '& > *:nth-child(3)': { gridArea: '2 / 1 / 3 / 2', minHeight: 300 },
            '& > *:nth-child(4)': { gridArea: '2 / 2 / 3 / 3', minHeight: 300 },
            '& > *:nth-child(5)': { gridArea: '3 / 1 / 4 / 4', minHeight: 300 },
        },
        root: {
            py: 2, overflowY: 'auto',
            gap: 2, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridTemplateRows: 'repeat(3, 1fr)',
            '& .MuiCard-root': {
                display: 'flex', flexDirection: 'column', p: 3.75, gap: 2,
                '& > div': {
                    p: 0
                },
                '& .MuiCardHeader-title': {
                    fontWeight: 700,
                    '& > .MuiLink-root[data-route]': {
                        textDecoration: 'none',
                        fontSize: '.5em',
                        fontWeight: 400,
                        marginLeft: '1em',
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > .MuiSvgIcon-root': {
                            fontSize: 'inherit'
                        }
                    },
                    '& > .MuiButtonBase-root[data-route]': {
                        p: .5,
                        minWidth: 0
                    }
                },
                '& .MuiTableCell-root:first-child, & .MuiTableCell-root:first-child > .MuiTypography-root': {
                    pl: 0,
                    justifyContent: 'left',
                    textAlign: 'left'
                },
                '& .MuiTableCell-root:last-child, & .MuiTableCell-root:last-child > .MuiTypography-root': {
                    pr: 0
                },
                '& .MuiTableCell-head': {
                    bgcolor: 'background.paper'
                },
                '& .MuiTableCell-root .routerlink-icon': {
                    display: 'none!important'
                }
            }
        }
    }
}

styles.desktop.inhabitant = { ...styles.desktop.root, ...styles.desktop.inhabitant };
styles.desktop.employee = { ...styles.desktop.root, ...styles.desktop.employee };

function CardHeader(props: CardHeaderProps) {
    return <MuiCardHeader {...props}
        titleTypographyProps={{ variant: 'h1', ...props?.titleTypographyProps }}
        subheaderTypographyProps={{ variant: 'body2', ...props?.subheaderTypographyProps }}
    />;
}

function getWidth(w?: number) {
    if (isMobile()) {
        return 'auto';
    }

    w || (w = 1);
    // return w * 345 + (16 * (w - 1));

    return 'calc(' + (w * 100 / 3) + '% - ' + (16 * (w - 1)) + 'px)';
}

function MeterWidget() {
    const [data] = useRemoteList<Meter>('MeterList');
    return <Card sx={{ flex: 1 }}>
        <CardHeader title="Счетчики" subheader="В случае просрочки поверки, показания счетчика не принимаются" />
        <CardContent sx={{ gap: 2, display: 'flex', flexDirection: 'column' }} >
            {data?.map(x => <DatePicker
                label={x.Name ? (x.Name + ' (' + x.Number + ')') : x.Number}
                value={moment(x.NextCheck)}
                readOnly={true} />)}
        </CardContent>
    </Card>;
}


function TasksWidget() {
    return <Card sx={{ width: getWidth() }}>
        <CardHeader title="Задачи" subheader={<>Активных: <b>1</b> Просрочено: <b>0</b></>} />
        <CardContent>
            <FormGroup>
                <FormControlLabel control={<Checkbox />} label={<NavLink route={[['guide', 'welcome']]}>Пройти ознакомительный тур</NavLink>} />
            </FormGroup>
        </CardContent>
    </Card>;
}

export default function Dashboard() {
    const ism = useIsMobile();
    const tenant = useTenant();

    if (ism) {
        return <Box sx={{ overflow: 'auto', p: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <TenantSelectorComponent />

                    <AccentPaper sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h2">
                            <span style={{ fontWeight: 400 }}>Ваш баланс: </span>
                            {getUser().Tenants.map(x => x.Houses?.map(x => x.Balance || 0).reduce((a, b) => a + b, 0)).filter(x => x).reduce((a, b) => a + b, 0)} ₽
                        </Typography>
                        <NavLink sx={{ textDecoration: 'none' }} href="#">
                            <Typography variant="caption" display="flex" alignItems="center">Пополнить <KeyboardArrowRight fontSize="inherit" /></Typography>
                        </NavLink>
                    </AccentPaper>

                    <Box display="flex" gap={1} sx={{ "&>div": { flex: 1 } }}>
                        <BillWidget />

                        <MeasuresWidget />
                    </Box>
                </Box>

                <EntryPassWidget />
                <RequestWidget />
                <ServiceWidget />
                <PostWidget />
            </Box>
        </Box>;
    } else if (hasRoles()) {
        return <Box sx={styles.desktop.employee}>
            <RequestWidget />
            <PaymentWidget />

            <EntryPassWidget />
            <MeasuresWidget />

            {/*<MeterWidget />*/}

            <PostWidget />
        </Box>;
    } else {
        return <Box sx={styles.desktop.inhabitant}>
            <RequestWidget />
            <BillWidget />

            <EntryPassWidget />
            <MeasuresWidget />
            <MeterWidget />

            <PostWidget />
        </Box>;
    }
}