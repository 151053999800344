import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import { getUser, isAuthentificated, listenUser } from './models/Identity';
import AppBar from './nav/AppBar';
import { menu, useAppRoute } from './nav/AppRoute';
import TenantSelector from './nav/TenantSelector';
import UserMenu from './nav/UserMenu';
import Login from './screens/Login';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/ru';
import { useState } from 'react';
import RouterGuide from './guides/RouterGuide';
import { MenuBars } from './icons';
import { useIsMobile, useRoute, useTenant, useTenantId } from './models/Hooks';
import { getTenant } from './models/Tenant';
import AppDrawer from './nav/AppDrawer';
import BottomBar from './nav/BottomBar';
import { getTheme } from './theme';

moment.locale('ru');

function Body() {
  const [route, path, current, menuItem] = useAppRoute(menu);
  return <>{menuItem?.element && menuItem.element()}</>;
}

export default function App() {
    const ism = useIsMobile();
    const [open, setOpened] = useState(!ism);
    const [auth, setAuth] = useState(isAuthentificated());
    useRoute(() => open && ism && setOpened(false))
    const tenantId = useTenantId();
    listenUser(() => setAuth(isAuthentificated()));

    const theme = getTheme();

    function buildCloseDrawer(open: boolean) {
        return <IconButton onClick={() => setOpened(!open)}>
            {MenuBars}
        </IconButton>;
    };

    function buildLayout() {
        return <Box id="ukroot-wrapper" key={'body-' + tenantId}
            sx={{
                background: ism ? '#fff' : 'linear-gradient(212.78deg, #EBF5FF 21.68%, #EAE8FD 94.47%)',
                display: 'flex',
                flexDirection: ism ? 'column' : 'row-reverse',
                height: '100vh',
                overflow: 'hidden',
                '& *': {
                    '--color-primary': theme.palette.primary
                }
            }}>
            <CssBaseline />

            <Box component="main"
                sx={{ overflowY: ism ? undefined : 'hidden', overflowX: 'hidden' }}
                style={{ flexGrow: 1, display: 'flex', flex: 1, flexDirection: 'column' }}>
                <AppBar position="relative" open={open} elevation={0}>
                    <Toolbar sx={{ gap: 1 }}>
                        {ism ? null : <><TenantSelector /><div style={{ flex: 1 }} /></>}
                        <Typography variant="h3" pr={4}>
                            {ism ? 'Здравствуйте,' : getTenant()?.Person?.Surname} {getTenant()?.Person?.Name || getUser()?.Name}
                        </Typography>
                        {ism ? <><div style={{ flex: 1 }} />{buildCloseDrawer(open)}</> : <UserMenu />}
                    </Toolbar>
                </AppBar>

                <Box
                    sx={ism ? styles.body!.mobile : styles.body!.main}
                    style={{ overflow: ism ? undefined : 'hidden' }}>
                    <Body />
                </Box>
            </Box>

            {ism ? <BottomBar /> : null}

            <AppDrawer key={'drawer' + open} open={open} />

            <RouterGuide />
        </Box>;
    }

    return <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {auth ? buildLayout() : <Login />}
        </LocalizationProvider>
    </ThemeProvider>;
}

const styles = {
    body: {
        main: { mx: 3, mb: 2 },
        mobile: { display: 'flex', flex: 1, flexDirection: 'column', flexGrow: 1, position: 'relative' } 
    }
}

Object.assign(styles.body.main, styles.body.mobile);
(styles.body.mobile as any)!.overflow = 'hidden';