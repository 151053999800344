import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { CSSObject, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, Theme, Toolbar, styled, useTheme } from '@mui/material';
import { useState } from 'react';
import { UrlRoute } from '../Router';
import Title from '../icons/Title';
import { useIsMobile, useTenant, useUser } from '../models/Hooks';
import { root } from '../models/Proxy';
import { isMobile } from '../utils';
import { IAppMenu, IAppMenuItem, IAppMenuNode, useAppRoute } from './AppRoute';

export const drawerWidth = isMobile() ? undefined : 220;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => (isMobile() ? {
    display: 'none'
} : {
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: isMobile() ? 0 : theme.spacing(5.5),
    [theme.breakpoints.up('sm')]: {
        width: isMobile() ? 0 : theme.spacing(6.5),
    },
});

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        overflowY: 'auto',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        boxShadow: '0 0 10px 0 #7B818F26',
        left: 0,
        '&.MuiDrawer-docked': {
            overflowY: 'visible',
            ...(isMobile() ? {
                position: 'fixed',
                height: '100%',
                zIndex: 1
            } : undefined)
        },
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        '& > .MuiDrawer-paper': {
            overflow: 'hidden',
            height: 'auto',
            minHeight: '100%',
        },
        '& .MuiListItemIcon-root > svg': {
            width: '2rem',
            height: '2rem'
        },
        '& .MuiTypography-root': {
            whiteSpace: 'normal'
        }
    }),
);

const StyledCollapsibleNode = styled(ListItem)({
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
        width: 0,
        height: 0,
        transition: 'all 100ms cubic-bezier(0.4, 0, 0.2, 1)'
    },
    '& .MuiTypography-root': {
        fontWeight: 'bold',
    },
    '&:hover .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
    },
    '&:hover *': {
        color: '#000',
    }
});

interface DrawerProps extends MuiDrawerProps {
    title: string,
    showLogo?: boolean
    menu: IAppMenu,
    open: boolean
}

interface BaseListProps {
    op: boolean,
    open: boolean,
    route: UrlRoute,
    currentItem: IAppMenuItem,
    isAdmin: boolean,
    mob: boolean,
    handleDrawerClose: () => any
}

function MenuExpandableNode({ x, op, open, isAdmin, mob, handleDrawerClose, route, currentItem }: { x: IAppMenuNode } & BaseListProps) {
    const [exp, setExp] = useState(true);
    const menuList = <MenuList op={op} open={exp} menu={x.children!} isAdmin={isAdmin} mob={mob} handleDrawerClose={handleDrawerClose} route={route} currentItem={currentItem} />;

    if (!op) {
        return <>
            <Divider key={x.id} />
            {menuList}
        </>;
    }

    return <>
        <StyledCollapsibleNode id={x.id} key={x.title?.toString()} onClick={() => setExp(!exp)} >
            {exp ? <ExpandLess color="disabled" /> : <ExpandMore color="disabled" />}
            <ListItemText secondary={x.title} secondaryTypographyProps={{ sx: styles.listItemSecondary(op) }} />
        </StyledCollapsibleNode>

        <Collapse key={'collapse' + x.id} in={exp} timeout="auto" unmountOnExit>
            {menuList}
        </Collapse>
    </>
}

function MenuList({ op, open, menu, isAdmin, mob, handleDrawerClose, route, currentItem }: { menu: (IAppMenuNode | null)[] } & BaseListProps) {
    const theme = useTheme();

    return <List sx={{ padding: 0 }}>
        {menu
            .filter(x => !x?.tenanted || isAdmin)
            .filter(x => !x || x.icon || x.title)
            .map((x, i) => x?.path !== undefined ? <ListItem key={x.title?.toString()}
                id={x.id}
                disablePadding
                sx={{ display: 'block' }}
                onClick={() => { mob && handleDrawerClose(); route.setPath(x.path!.replace(/\/\*$/, '').replace(/\/:.*$/, '')); }}>
                <ListItemButton sx={styles.listItemButton(op, currentItem == x, theme)} >
                    <ListItemIcon sx={styles.listItemIcon(op, currentItem == x, theme)}>{x.icon}</ListItemIcon>
                    <ListItemText primary={x.title} primaryTypographyProps={{ variant: 'menu', sx: styles.listItemText(op, x, currentItem == x) }} />
                </ListItemButton>
            </ListItem> :
                x?.title ? x.children ? <MenuExpandableNode x={x} route={route} currentItem={currentItem} op={op} open={open} isAdmin={isAdmin} mob={mob} handleDrawerClose={handleDrawerClose} /> :
                    <ListItem id={x.id} key={x.title?.toString()}>
                        <ListItemText secondary={x.title} secondaryTypographyProps={{ sx: styles.listItemSecondary(op) }} />
                    </ListItem> :
                    <Divider key={i} sx={{ my: 2 }} />)}
    </List>
}

export default function Drawer({ title, menu, open, children, showLogo, ...props }: DrawerProps) {
    const ism = useIsMobile();
    const [op, setOpened] = useState(open);
    const handleDrawerOpen = () => setOpened(true);
    const handleDrawerClose = () => setOpened(false);
    const [route, path, current, currentItem] = useAppRoute(menu, () => op && ism && setOpened(false));

    const tenant = useTenant();
    const user = useUser();
    const isAdmin = tenant?.OwnerId == user.Id;

    return <StyledDrawer variant="permanent" open={op} PaperProps={{ sx: { position: 'relative', border: 'none' } }} style={{ zIndex: 9999 }} {...props}>
        {showLogo ? <Toolbar sx={{ p: 0, pl: 3, gap: 2, position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
            {tenant?.Logo ? <img src={`${root}files/${tenant.Id}/${tenant.Logo}`} style={{ maxWidth: '100%', padding: 16 }} alt={tenant.Name} /> : <>
                <Title />
                {op ? <svg width="87" height="26" viewBox="0 0 87 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.58 26L24.696 15.74H22.032V26H16.956V15.74H14.292L6.408 26H0.396L10.116 13.328L0.864 0.799998H6.768L14.292 10.988H16.956V0.799998H22.032V10.988H24.696L32.22 0.799998H38.124L28.872 13.328L38.592 26H32.58ZM56.0523 0.799998H61.3803L49.5723 26H41.8323V21.392H46.5123L47.9523 18.26L39.7803 0.799998H45.1083L50.5803 12.644L56.0523 0.799998ZM80.1672 26L72.0672 15.74H69.0792V26H63.9672V0.799998H69.0792V10.988H72.0672L79.8072 0.799998H85.7112L76.1712 13.328L86.1792 26H80.1672Z" fill="#1F2128" />
                </svg> : null}
            </>}
            {/**mob ? null : <IconButton onClick={op ? handleDrawerClose : handleDrawerOpen} sx={{
                backgroundColor: '#fff',
                borderWidth: 1,
                borderStyle: 'solid',
                position: 'absolute',
                right: 'calc(-.75rem - 1px)',
                width: '1.5rem',
                height: '1.5rem',
                zIndex: 10000
            }}>
                {op ? <ChevronLeft fontSize="small" /> : <ChevronRight fontSize="small" />}
            </IconButton>/**/}
        </Toolbar> : null}

        <MenuList op={op} open={open} menu={menu} isAdmin={isAdmin} mob={ism} handleDrawerClose={handleDrawerClose} route={route} currentItem={currentItem} />

        {children}
    </StyledDrawer>;
}

export const styles: { [name: string]: (open: boolean, ...props: any) => React.CSSProperties } = {
    listItemIcon: (open: boolean, current: boolean, theme: Theme) => ({
        transition: 'all 225ms',
        zoom: .75,
        minWidth: 0,
        mr: open ? 1.5 : 0,
        opacity: current ? 1 : .8,
        color: current ? 'primary.main' : undefined,
        justifyContent: 'center',
        '& path:not([stroke]):not([fill="white"]), & rect': {
            fill: theme.palette.primary.main
        },
        '& path[stroke]': {
            stroke: theme.palette.primary.main
        }
    }),
    listItemButton: (open: boolean, current: boolean, theme: Theme) => {
        return {
            minHeight: 24,
            backgroundColor: current ? theme.palette.background.paper : 'none',
            justifyContent: open ? 'initial' : 'center',
            flexDirection: open ? 'row' : 'column',
            py: .6,
            mx: 1,
            borderRadius: 3
        }
    },
    listItemText: (open: boolean, item: any, current: boolean) => {
        return {
            transition: 'all 225ms',
            //opacity: open ? current ? 1 : .8 : .8,
            //maxWidth: open ? 'auto' : 0,
            color: /**current ? 'primary.main' : /**/item?.color,
            fontSize: open ? undefined : '0.66rem',
            fontWeight: current ? 600 : undefined,
            lineHeight: 1.57143
        }
    },
    listItemSecondary: (open: boolean) => {
        return {
            opacity: open ? 1 : 0,
            maxWidth: open ? 'auto' : 0,
            color: 'secondary',
            fontWeight: '500 !important',
            fontSize: '0.75rem'
        }
    }
}