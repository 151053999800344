import { Breadcrumbs, styled } from "@mui/material";
import { isMobile } from "../../utils";

export const DataBreadcrumbs = styled(Breadcrumbs)(() => {
    const ism = isMobile();
    return {
        marginBottom: '1rem',
        "& li > *": {
            fontWeight: 400,
            fontSize: ism ? undefined : '1.5rem',
            fontWeight: ism ? 600 : undefined,
            display: 'flex',
            alignItems: 'flex-end',
            color: 'rgba(0, 0, 0, 0.87)'
        },
        "& li:not(:last-child)": {
            cursor: 'pointer'
        }
    }
});