import { trySubscribeFCM } from "./FCM";
import { webPushSubscribe } from "./WebPush";

try {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            trySubscribeFCM().then(x => {
                if (!x) {
                    webPushSubscribe();
                }
            });
        }
    });
} catch (e) {

}