import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Step } from 'react-joyride';
import BaseUserComponent from '../components/base/BaseUserComponent';
import Guide from '../guides/Guide';
import { MenuBars } from '../icons';
import { logout } from '../models/Identity';

export const userMenuGuide: Step[] = [{
    target: '#user-menu',
    title: 'Профиль пользователя',
    placementBeacon: 'right-start',
    content: <>
        Из меню <b>Профиль пользователя</b> можно перейти в разделы:
        <ul>
            <li><b>Профиль</b> - контактная информация, где можно изменять свое ФИО, контакты для связи, фото. Также из него можно перейти в раздел смены пароля.</li>
            <li><b>Баланс и платежи</b> - Информация о балансе в конкретном или во всех Проектах, в которых Вы состоите</li>
            <li><b>Техподдержка</b> - Решение вопросов по работе с информационной системой <Typography color="primary">УК</Typography></li>
        </ul>
    </>
}];

interface State {
    anchorEl?: HTMLElement
}

export default class UserMenu extends BaseUserComponent<{}, State>{
    handleClose = () => this.setState({ anchorEl: undefined })

    onLogout = () => {
        logout();
        this.handleClose();
    }

    render() {
        return <div>
            <Button
                variant="contained"
                color="info"
                sx={{ minWidth: 0, p: 1 }}
                onClick={e => this.setState({ anchorEl: e.currentTarget })}>
                {/*{!isMobile() && isAuthentificated() ? getUser().Name : null}*/}
                {MenuBars}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={this.state?.anchorEl}
                open={!!this.state?.anchorEl}
                onClose={this.handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={this.handleClose} data-route="page=profile&subpage=">Мои данные</MenuItem>
                <MenuItem onClick={this.handleClose} data-route="/profile/account">Баланс и платежи</MenuItem>
                <MenuItem onClick={this.handleClose}>Техподдержка</MenuItem>
                <MenuItem onClick={this.onLogout}>Выйти</MenuItem>
            </Menu>
            <Guide guide="userMenu" />
        </div>
    }
}