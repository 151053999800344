import { SquareRounded } from "@mui/icons-material";
import { Box, Button, Card, CardHeader, FormControl, FormLabel, InputAdornment, Paper, SxProps, Theme } from "@mui/material";
import route from "../../Router";
import ImageInput from "../../components/ImageInput";
import { FormApiRef } from "../../components/data/ApiRef";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput } from "../../components/form/TextInput";
import logoimg from '../../icons/logoimg.png';
import { useRoute, useTenantId } from "../../models/Hooks";
import { fetchUser, getUser } from "../../models/Identity";
import Proxy, { root } from "../../models/Proxy";
import { getTenantId } from "../../models/Tenant";
import { Tenant } from "../../models/Types";
import { primary } from "../../theme";
import Title from "../../icons/Title";

export class TenantForm extends AbstractTypedDataForm<Tenant> {
    protected getTypeId(): string {
        return 'Tenant';
    }

    protected createEntity() {
        return new Tenant();
    }

    protected onFieldChange(field: string, value: any) {
        if (field === 'PrimaryColor' &&
            value &&
            !/^#[0-9A-F]{6}[0-9a-f]{0,2}$/i.test(value)) {
            return;
        }

        super.onFieldChange(field, value);

        if (field === 'PrimaryColor') {
            this.forceUpdate();
        }
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        return {
            ...this.props.sx,
            overflow: 'auto',
            flexDirection: 'column'
        };
    }

    buildImageField(entity: Tenant | undefined, title: string, name: keyof Tenant, def: any) {
        function upload(body:any) {
            Proxy.postPlain('FileUpload' + name, {
                headers: {},
                body
            }).then((res: string[]) => {
                entity && res[0] && (entity[name] = res[0]);
            });
        }

        return <Card sx={{ p: 2 }} style={{ width: 500, flex: 'none' }}>
            <CardHeader title={title} />
            <FormControl>
                <ImageInput sx={{ borderRadius: 0 }}
                    value={entity && entity[name] ? `${root}files/${getTenantId()}/${entity[name]}` : def}
                    onChange={e => {
                        const input = e.target as HTMLInputElement;
                        const l = input.files?.length;
                        if (l) {
                            const formData = new FormData();
                            for (var i = 0; i < l; i++) {
                                formData.append('file' + i, input.files![i]);
                            }

                            upload(formData);
                        }
                    }} />
            </FormControl>

            <Button variant="outlined" color="error" onClick={upload}>Удалить {title.split(' ')[0]}</Button>
        </Card>;
    }

    protected buildItems(entity?: Tenant) {
        return <>
            <Paper sx={{ p: 2 }} style={{ width: 500, flex: 'none' }}>
                <FormTextInput form={this} entity={entity} name="Name" label="Наименование" />

                <FormTextInput form={this} entity={entity} name="Code" label="Уникальное имя в адресной строке" inputProps={{
                    placeholder: 'мойжк',
                    endAdornment: '.jukapp.ru'
                }} />

                <FormTextInput form={this} entity={entity} name="PrimaryColor" label="Основной цвет (в hex формате)" inputProps={{
                    placeholder: primary,
                    endAdornment: <InputAdornment position="end"><SquareRounded htmlColor={entity?.PrimaryColor || primary} /></InputAdornment>
                }} />

                <Box sx={{ display: 'flex', marginTop: 2 }}>
                    {(!entity?.Id || entity?.OwnerId == getUser().Id) ? super.buildButtons(entity) : null}
                </Box>
            </Paper>

            {this.buildImageField(entity, 'Изображение на странице авторизации', 'LoginImage', logoimg)}

            {this.buildImageField(entity, 'Логотип', 'Logo', <>
                <Title />
                <svg width="87" height="26" viewBox="0 0 87 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.58 26L24.696 15.74H22.032V26H16.956V15.74H14.292L6.408 26H0.396L10.116 13.328L0.864 0.799998H6.768L14.292 10.988H16.956V0.799998H22.032V10.988H24.696L32.22 0.799998H38.124L28.872 13.328L38.592 26H32.58ZM56.0523 0.799998H61.3803L49.5723 26H41.8323V21.392H46.5123L47.9523 18.26L39.7803 0.799998H45.1083L50.5803 12.644L56.0523 0.799998ZM80.1672 26L72.0672 15.74H69.0792V26H63.9672V0.799998H69.0792V10.988H72.0672L79.8072 0.799998H85.7112L76.1712 13.328L86.1792 26H80.1672Z" fill="#1F2128" />
                </svg></>)}
        </>;
    }

    buildButtons(entity?: Tenant) {
        return [];
    }
}

function apiRef(api?: FormApiRef) {
    api?.onSave(() => fetchUser());
    api?.onDelete(() => fetchUser());
}

export default function TenantView({ idRoute }: { idRoute: string }) {
    useRoute();
    useTenantId();
    const id = route.get(idRoute) || route.get('id') || getTenantId();
    return <TenantForm id={parseInt(id) || 0} apiRef={apiRef} />;
}