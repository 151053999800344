export enum ResponseStatus{
    /// <summary>
    /// Статус неопределен
    /// </summary>
    None = 0,

    /// <summary>
    /// Требуется ответ
    /// </summary>
    Waiting = 1,

    /// <summary>
    /// Приглашение принято
    /// </summary>
    Accepted = 2,

    /// <summary>
    /// Приглашение отклонено
    /// </summary>
    Declined = 3,

    /// <summary>
    /// Возможно
    /// </summary>
    Maybe = 4
}

export enum OperationType {
    /// <summary>
    /// Начисление
    /// </summary>
    Invoice = 0,

    /// <summary>
    /// Платеж по начислению
    /// </summary>
    Payment = 1,

    /// <summary>
    /// Зачисление на счет
    /// </summary>
    Enrollment = 2,

    /// <summary>
    /// Возврат
    /// </summary>
    Refund = 3
}

export const operationTypes = {
    [OperationType.Invoice]: 'Начисление',
    [OperationType.Payment]: 'Платеж', // по начислению
    [OperationType.Enrollment]: 'Зачисление', // на счет
    [OperationType.Refund]: 'Возврат'
}

export enum PaymentState {
    None = 0,

    Open = 1,

    Payment = 2,

    Success = 3,
    Error = 4
}

export enum LocationType {
    /// <summary>
    /// Неизвестно
    /// </summary>
    Unknown = 0,

    /// <summary>
    /// Дом
    /// </summary>
    House = 1,

    /// <summary>
    /// Камера
    /// </summary>
    Camera = 2
}

export const locationTypes = {
    [LocationType.Unknown]: 'Неизвестно',
    [LocationType.House]: 'Дом',
    [LocationType.Camera]: 'Камера'
}

export enum RequestStatus {
    /// <summary>
    /// Открыта
    /// </summary>
    Open = 0,

    /// <summary>
    /// В обработке оператором
    /// </summary>
    Analyze = 1,

    /// <summary>
    /// Запланирована реализация
    /// </summary>
    Planing = 2,

    /// <summary>
    /// Исполнение
    /// </summary>
    Implementing = 3,

    /// <summary>
    /// Завершена успешно
    /// </summary>
    Completed = 10,

    /// <summary>
    /// Отменена
    /// </summary>
    Canceled = 20,

    /// <summary>
    /// Завершена, работа не принята (в полном объеме)
    /// </summary>
    Rejected = 30
}

export const defaultRequestStatusTitle = 'Открыта';
export const requestStatuses = {
    [RequestStatus.Open]: defaultRequestStatusTitle,
    [RequestStatus.Planing]: "Запланировано",
    [RequestStatus.Analyze]: "В обработке",
    [RequestStatus.Implementing]: "В работе",
    [RequestStatus.Completed]: "Завершена",
    [RequestStatus.Canceled]: "Отменена",
    [RequestStatus.Rejected]: "Не принята"
};
export const requestStatuseMap: [RequestStatus, string][] = [];
for (var rs in requestStatuses) {
    requestStatuseMap.push([rs as any, (requestStatuses as any)[rs] as string]);
}

export enum ActivityType {
    Unknown = 0,
    Create = 1,
    Comment = 2,
    StatusChange = 3
}

export const activityTypes = {
    [ActivityType.Unknown]: "Неизвестно",
    [ActivityType.Create]: "Заявка создана",
    [ActivityType.Comment]: "Комментарий",
    [ActivityType.StatusChange]: "Изменение статуса"
};

export enum PeriodicServiceType {
    Custom = 0,
    Area = 1,
    Residents = 2
}

export const periodicServiceTypeMap = {
    [PeriodicServiceType.Custom]: "Произвольный",
    [PeriodicServiceType.Area]: "Площадь домовладения",
    [PeriodicServiceType.Residents]: "Количество жильцов"
};
export const periodicServiceTypes: [PeriodicServiceType, string][] = [];
for (var rs in periodicServiceTypeMap) {
    periodicServiceTypes.push([rs as any, (periodicServiceTypeMap as any)[rs] as string]);
}