import { Box, Checkbox, FormControlLabel, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import route from '../Router';
import Logo from '../icons/Logo';
import { useRoute } from '../models/Hooks';
import { isAuthentificated, isInit, login, register } from '../models/Identity';
import './login.css';
import logoimg from '../icons/logoimg.png'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { isMobile } from '../utils';
import { getTenant } from '../models/Tenant';
import { root } from '../models/Proxy';

route.register('login');

const styles = {
    typography: {
        alignSelf: 'center'
    },
    wrapper: {

    },
    img: {
        backgroundImage: 'url(' + logoimg + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        flex: 1
    },
    form: { p: '10%', maxWidth: 400, display: 'flex', flexDirection: 'column', gap: 5, justifyContent: 'center' }
}

const text: any = {
    login: 'Войти',
    register: 'Зарегистрироваться'
}

export default function Login() {
    const [route, path] = useRoute();
    const [error, setError] = useState<string>();
    const theme = useTheme();

    const [showPassword, setShowPassword] = useState(false);

    const mode = route.get('login');

    const submit = (e: any) => {
        const email = e.target.querySelector('[name=email]').value,
            password = e.target.querySelector('[name=password]').value;

        if (mode !== 'register' || (document.location.port != '3000' && document.location.hash != '#allowregister')) {
            login('email', { Email: email, Password: password }).then(x => {
                if (isAuthentificated()) {
                    route.setState('login');
                    if (route.get('page') === 'login') {
                        route.setState([['page']]);
                    }
                } else {
                    setError('Неверный логин/пароль');
                }
            });
        } else {
            register(email, password, e.target.querySelector('[name=password2]').value)
                .then(x => {
                    if (isAuthentificated()) {
                        route.setState([['guide', 'welcome'], ['login']]);
                    } else switch (x.result?.errors?.map((x: any) => x.code)[0]) {
                        case 'DuplicateUserName': setError('Пользователь с таким логином уже существует'); break;
                        default: setError('Некорректные данные'); break;
                    }
                });
        }

        setError(undefined);

        e.stopPropagation();
        e.preventDefault();
    }

    let items: React.ReactNode;
    if (isInit()) {
        items = <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, '& .MuiFormLabel-root': { fontSize: '.875em' } }}>
                <FormControl>
                    <FormLabel>Логин</FormLabel>
                    <OutlinedInput name="email" />
                </FormControl>

                <FormControl>
                    <FormLabel>Пароль</FormLabel>
                    <OutlinedInput name="password" type={showPassword ? 'text' : 'password'} endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword((show) => !show)} edge="end" color="primary">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    } />
                    {error && mode !== 'register' ? <FormHelperText error>{error}</FormHelperText> : null}
                </FormControl>

                {mode == 'register' ?
                    <FormControl>
                        <FormLabel>Подтвердите пароль</FormLabel>
                        <OutlinedInput name="password2" type="password" />
                        {error ? <FormHelperText error>{error}</FormHelperText> : null}
                    </FormControl> :
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', '& .MuiTypography-root': { fontSize: '.875em' } }}>
                        <FormControlLabel
                            control={<Checkbox name="save" sx={{ color: theme.palette.primary.main }} />}
                            label="Запомнить меня" />

                        <Typography component="a" href="#" fontSize="sm" color="primary" sx={{ textDecoration: 'none' }}>Забыли пароль?</Typography>
                    </Box>}
            </Box>

            <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column',  }}>
                <Button variant="contained" type='submit'>{text[mode || 'login']}</Button>

                {/**<Typography fontSize="sm" sx={styles.typography}>
                <a href="#" style={{ color: theme.palette.action.active }}>Восстановить пароль</a>
            </Typography>/**/}

                <Button variant="contained" color="info">{mode !== 'register' ? 'Еще не в УК? Зарегистрируйся' : 'Войти'}</Button>
            </Box >
        </>;
    }

    const tenant = getTenant();

    return <Box sx={{ bgcolor: '#fff', m: 4, flex: 1, display: 'flex', borderRadius: 2.5, overflow: 'hidden' }}>
        {isMobile() ? null : <Box sx={tenant?.LoginImage ? { ...styles.img, backgroundImage: `url(${root}files/${tenant.Id}/${tenant.LoginImage})` } : styles.img} />}

        <Box component="form" onSubmit={submit} sx={isMobile() ? { ...styles.form, flex: 1 } : styles.form}>
            <Box sx={{ alignItems: 'center', gap: 3, display: 'flex', flexDirection:'column' }}>
                {tenant?.Logo ?
                    <img src={`${root}files/${tenant.Id}/${tenant.Logo}`} style={{ minWidth: 200, maxWidth: '100%' }} alt="ЖУК" /> :
                    <Logo width={100} height={100} />}
                {isMobile() ? null : <Typography variant="h1" textAlign="center">Добро пожаловать в сервис управления жильем!</Typography>}
                <Typography variant="body2" color="textDisabled">Авторизуйтесь, чтобы продолжить</Typography>
            </Box>

            {items}
        </Box>
    </Box>;
}