import { AccessTime } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Typography } from "@mui/material";
import moment from "moment";
import AccentPaper from "../../components/Paper";
import { TextInput } from "../../components/form/TextInput";
import { Charges } from "../../icons";
import { useIsMobile, useRemoteData } from "../../models/Hooks";
import { Bill } from "../../models/Types";

function DesktopWidget() {
    const [data] = useRemoteData<Bill[]>('BillActiveList');

    return <Card sx={{ flex: 1 }}>
        <CardHeader title="Квитанции на оплату" />
        <CardContent sx={{ gap: 2, display: 'flex', flexDirection: 'column', flex: 1 }}>
            {data?.map(x => <TextInput
                name={x.Id}
                onChange={e => { }}
                label={moment(x.CreateDate).format('MMMM Y')}
                value={x.Amount} />)}
            <Button variant="contained">Оплатить картой</Button>
            <Button variant="contained">Сформировать Квитанцию</Button>
        </CardContent>
    </Card>;
}

function MobileWidget() {
    const [bill] = useRemoteData<{
        Amount: number
        LastBill: Date
    }>('BillAmount');

    return <AccentPaper sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'space-between' }}>
        <Box display="flex" gap={1}>
            <Box>
                <Typography variant="h1">
                    {bill?.Amount} ₽
                </Typography>
                <Typography component="div" variant="caption">к оплате по вашему дому</Typography>
            </Box>
            {Charges}
        </Box>
        {bill ? <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <Button variant="contained" color="info" startIcon={<AccessTime />} sx={{ fontSize: 8, p: 1 }}>
                Последнее начисление<br />
                {moment(bill?.LastBill).calendar()}
            </Button>
            <Button variant="contained">Оплатить</Button>
        </Box > : null}
    </AccentPaper>
}

export function BillWidget() {
    const ism = useIsMobile();
    return ism ? <MobileWidget /> : <DesktopWidget />;
}