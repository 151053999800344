import { Analytics, getAnalytics } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Messaging, deleteToken, getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { isAuthentificated, listenUser } from "../Identity";
import Proxy from "../Proxy";

/** FCM */
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD_aVTe-jQk2YhvXWkiYZC3PilzmPJBF6k",
    authDomain: "jukapp-7b257.firebaseapp.com",
    projectId: "jukapp-7b257",
    storageBucket: "jukapp-7b257.firebasestorage.app",
    messagingSenderId: "152818717996",
    appId: "1:152818717996:web:00bf46568888b3de4ec889",
    measurementId: "G-8SK82WVFLC"
};

const vapidKey = 'BC__2_OC7FV4eqRK9PZfA1vEn6868K7QNYS-N76rQ7VGx56quj6uOht2zvJkUl3gBsVMAZhEajuUHFnJHbyDlRo';

var app: FirebaseApp;
var messaging: Messaging;
var analytics: Analytics;

function Initialize() {
    if (!app) {
        // Initialize Firebase
        app = initializeApp(firebaseConfig);
        messaging = getMessaging(app);
        analytics = getAnalytics(app);

        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            // Update the UI to include the received message.
            debugger;
        });
    }
}

function sendTokenToServer(currentToken: string) {
    if (!isTokenSentToServer()) {
        console.log('Sending token to server...', currentToken);

        Proxy.post('FCMAddToken', currentToken).then(x => setTokenSentToServer(true));
    } else {
        console.log('Token already sent to server so won\'t send it again unless it changes');
    }
}

function isTokenSentToServer() {
    return window.localStorage.getItem('sentToServer') === '1';
}

function setTokenSentToServer(sent: boolean) {
    window.localStorage.setItem('sentToServer', sent ? '1' : '0');
}

function resolveToken() {
    getToken(messaging, { vapidKey }).then((currentToken) => {
        if (currentToken) {
            sendTokenToServer(currentToken);
        } else {
            // Show permission request.
            console.log('No registration token available. Request permission to generate one.');
            // Show permission UI.
            setTokenSentToServer(false);
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        setTokenSentToServer(false);
    });
}
function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            // TODO(developer): Retrieve a registration token for use with FCM.
            // In many cases once an app has been granted notification permission,
            // it should update its UI reflecting this.
            resolveToken();
        } else {
            console.log('Unable to get permission to notify.');
        }
    });
}

function deleteTokenFromFirebase() {
    // Delete registration token.
    getToken(messaging).then((currentToken) => {
        deleteToken(messaging).then(() => {
            console.log('Token deleted.', currentToken);
            setTokenSentToServer(false);
            // Once token is deleted update UI.
        }).catch((err) => {
            console.log('Unable to delete token. ', err);
        });
    }).catch((err) => {
        console.log('Error retrieving registration token. ', err);
    });
}

export function trySubscribeFCM() {
    return isSupported().then(s => {
        if (s) {
            Initialize();
        }

        listenUser(() => {
            try {

                if (s && isAuthentificated()) {
                    requestPermission();
                } else {
                    deleteTokenFromFirebase();
                }
            } catch (e) {
                console.log(e);
            }
        });

        return s;
    });
}