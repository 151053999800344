import { Step } from "react-joyride";
import { UrlRoute } from "../Router";
import Guide, { guides } from "../guides/Guide";
import * as icons from "../icons";
import { useRoute } from "../models/Hooks";
import { logout } from "../models/Identity";
import Login from "../screens/Login";
import BillView from "../screens/bill";
import Dashboard from "../screens/dashboard";
import EntryPassView from "../screens/entrypass";
import HouseView from "../screens/houses";
import InhabitantView from "../screens/inhabitants";
import LocationView from "../screens/locations";
import MeasureView from "../screens/measure";
import MeterView from "../screens/meter";
import PaymentView from "../screens/payment";
import PostView from "../screens/post";
import ProfileView from "../screens/profile";
import ProjectView from "../screens/project";
import ProvidedServiceView from "../screens/providedService";
import RequestView from "../screens/request";
import VersionView from "../screens/version";

const projectManageMenu: Step[] = [
    {
        target: '#project-manage-menu',
        title: 'Управление ЖК',
        content: <>В меню <b>Управление ЖК</b> собраны основные разделы системы, необходимые для повседневное работы</>,
    },
    {
        target: '#emploees-menu',
        title: 'Сотрудники',
        content: <><b>Сотрудники</b> Проекта могут
            <ul>
                <li>Брать в работу заявки на услуги</li>
                <li>Администрировать Проект</li>
                <li>Для каждого <b>Сотрудника</b> может быть создан отдельный пользовательский аккаунт</li>
            </ul>
        </>,
    },
    {
        target: '#project-menu-currency',
        title: 'Услуги',
        content: <>В разделе <b>Услуги</b> можно настроить стоимость предоставляемых услуг.
            <p>При создании нового Проекта в нем по умолчанию уже есть услуга <b>Тех обслуживание</b> стоимостью <b>1000 рублей</b></p>
        </>,
    },
    {
        target: '#locations-menu',
        title: 'Локации',
        content: <>
            В разделе <b>Локации</b> можно посмотреть дома, камеры, проходные или любые геолокационные точки.
            <p>В <b>Заявках</b> можно указывать <b>Локации</b> для любой заявки</p>
        </>,
    }
].map(x => {
    return {
        ...x,
        placementBeacon: 'right',
        placement: 'right'
    }
});
guides.projectManageMenu = projectManageMenu;

export interface IAppMenuItem {
    id?: string
    path?: string,
    title?: React.ReactNode,
    tenanted?: boolean,
    icon?: React.ReactNode,
    element?: () => React.ReactNode,
    color?: string
}
export interface IAppMenuNode extends IAppMenuItem {
    children?: (IAppMenuNode | null)[]
}

export type IAppMenu = (IAppMenuItem | null)[];

export const hierarchicalMenu: (IAppMenuNode | null)[] = [{
    title: 'Общее',
    children: [{
        id: 'desktop-menu',
        path: "",
        title: 'Аналитика',
        icon: icons.Analytics,
        element: () => <Dashboard />,
    },
    {
        id: 'measure-menu',
        path: "measure/*",
        title: 'Показания',
        icon: icons.Information,
        element: () => <MeasureView />,
    },
    {
        id: 'request-menu',
        path: "request/*",
        title: 'Заявки',
        icon: icons.Request,
        element: () => <RequestView />,
    },
    {
        id: 'providedService-menu',
        path: "service/*",
        title: 'Услуги',
        icon: icons.Services,
        element: () => <ProvidedServiceView />,
    },
    {
        id: 'bill-menu',
        path: "bill/*",
        title: 'Начисления',
        icon: icons.Charges,
        element: () => <BillView />,
    },
    {
        id: 'payment-menu',
        path: "payment/*",
        title: 'Платежи',
        icon: icons.Payments,
        element: () => <PaymentView />,
    },
    {
        id: 'entrypass-menu',
        path: "entrypass/*",
        icon: icons.Car,
        title: 'Пропуска',
        element: () => <EntryPassView />,
    }]
},
    null,
{
    id: 'project-manage-menu',
    title: <>Жилой комплекс<Guide guide="projectManageMenu" /></>,
    children: [/**
    {
        id: 'clients-menu',
        path: "clients/*",
        title: 'Клиенты',
        tenanted: true,
        icon: <Mood />,
        element: () => <Clients />,
    },
    {
        id: 'finance-menu',
        path: "finance",
        title: 'Расчеты',
        tenanted: true,
        icon: <CreditCard />,
        element: () => <Finance />,
    },/**
    {
        id: 'project-menu-currency',
        path: "currency",
        title: 'Услуги',
        tenanted: true,
        icon: <LeakAdd />,
        element: () => <CurrencyView />,
    },/**/
        {
            id: 'house-menu',
            path: "house/*",
            icon: icons.Houses,
            title: 'Домовладения',
            element: () => <HouseView />,
        },
        {
            tenanted: true,
            id: 'inhabitant-menu',
            path: "inhabitant/*",
            icon: icons.Users,
            title: 'Жильцы',
            element: () => <InhabitantView />,
        },
        {
            id: 'meter-menu',
            path: "meter/*",
            icon: icons.Meter,
            title: 'Счётчики',
            element: () => <MeterView />,
        },
        {
            id: 'news-menu',
            path: "news/*",
            title: 'Новости',
            icon: icons.News,
            element: () => <PostView />,
        }/**/,
        {
            id: 'locations-menu',
            path: "locations/*",
            icon: icons.Pin,
            title: 'Локации',
            element: () => <LocationView />,
        }/**/]
},
    null,
{
    tenanted: true,
    path: "project/*",
    title: 'Настройки',
    icon: icons.Settings,
    //color: 'primary.main',
    element: () => <ProjectView />,
},
{
    title: 'Выход',
    path: "login",
    icon: icons.Logout,
    //color: 'primary.main',
    element: () => {
        logout();
        return <Login />
    },
},
{
    path: "project/:id",
    element: () => <ProjectView />,
},
{
    path: "profile/:id",
    element: () => <ProfileView />,
},
{
    path: "version",
    element: () => <VersionView />,
}];

export const menu: IAppMenu = hierarchicalMenu.reduce((a, b) => b?.children ? [...a, b, ...b.children] : [...a, b], [] as IAppMenu);

export const route = menu.filter(x => x);

export function useAppRoute(menu: IAppMenu, onChange?: (path: string) => any): [UrlRoute, string, number, IAppMenuItem] {
    const [route, path] = useRoute(onChange);
    var rawMenu = (menu as IAppMenuNode[])
        .reduce((a, b) => b?.children ? [...a, b, ...b.children] : [...a, b], [] as IAppMenu)
        .reverse();

    var current = rawMenu.findIndex(x => (x?.path || x?.path === '') && path.startsWith(x.path.replace(/\/\*$/, '').replace(/\/:.*$/, '')));
    return [route, path, current, rawMenu[current]!];
}