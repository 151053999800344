import { AccessTimeRounded, AddRounded, ChevronRight } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Paper, Theme, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { PieItemId, PieValueType } from "@mui/x-charts";
import { PieChart } from '@mui/x-charts/PieChart';
import moment from "moment";
import { useState } from "react";
import { requestColumns, requestStatusColors, requestStatusMobileIcons } from ".";
import route, { NavLinkComponent } from "../../Router";
import NavLink, { NavButton } from "../../components/NavLink";
import DataTable, { BaseDataTable, ColumnData, Renderer } from "../../components/data/DataTable";
import { defaultRequestStatusTitle, requestStatuses } from "../../models/Enums";
import { useIsMobile, useRemoteList } from "../../models/Hooks";
import { Request } from "../../models/Types";
import { numberFormat } from "../../utils";
import { requestParse } from "./RequestForm";

const styles = {
    mobileIconWrapper: {
        p: .66,
        display: 'flex',
        borderRadius: .5
    },
    mobileListItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        textDecoration: 'none', p: 2,
        gap: 2,
        alignItems: 'flex-start'
    }
}

function MobileWidget() {
    const [data] = useRemoteList<Request>(`RequestList?query=${JSON.stringify({ Sort: { Property: 'CreateDate', Direction: 1 }, Count: 4, LazyLoad: true })}`);

    return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h2" sx={{
            "&,&>*": {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }
        }} >
            Ваши заявки
            <NavLink variant="caption" route={[['page', 'request'], ['id']]}>Все <ChevronRight fontSize="small" /></NavLink>
        </Typography>
        {data?.map(x => <Paper component={NavLinkComponent} sx={styles.mobileListItem} elevation={2} route={[['page', 'request'], ['id', x.Id]]}>
            <Box display="flex" flexDirection="column" minWidth={80} gap={.5}>
                <NavLink variant="h3">№{x.Id}</NavLink>
                <Typography variant="subtitle2">{moment(x.CreateDate).format('DD.MM.yy')}</Typography>
            </Box>
            <Box flex={1}>
                <Typography variant="h3" textAlign="left">{x.Name}</Typography>
            </Box>
            <Box sx={x.Status ? { ...styles.mobileIconWrapper, bgcolor: requestStatusColors[x.Status] || defaultRequestStatusColor } : styles.mobileIconWrapper}>
                {x.Status && requestStatusMobileIcons[x.Status] ? requestStatusMobileIcons[x.Status]() : <AccessTimeRounded color="warning" fontSize="small" />}
            </Box>
        </Paper>)}
    </Box>;
}

const epCollumns = [
    { name: 'ServiceIds', variant: 'h3', routerLink: true },
    { name: 'HouseIds', variant: 'caption' },
    { name: 'Status', variant: 'h4' },
    { name: 'CreateDate', variant: 'h4' }
].map(({ name, variant, ...n }) => {
    const col = { ...requestColumns.find(x => x.name === name), ...n } as ColumnData<Request>;
    if (col.routerLink) {
        col.routerParams = p => {
            p.push(['page', 'request']);
            return p;
        }
    }
    const renderer = col.renderer || ((x: any, o: Request, th: Theme, ta: BaseDataTable<Request>, d?: Renderer<Request>) => d ? d(x, o, th, ta, d) : x);
    col.renderer = (x, o, th, ta, d) => <Typography variant={variant as Variant}>{renderer(x, o, th, ta, d)}</Typography>
    return col;
});

function DesktopWidget() {
    const [d, setData] = useState<PieValueType[]>();
    const now = new Date();
    return <Card sx={{ flex: 1 }}>
        <CardHeader
            titleTypographyProps={{ display: 'flex', variant: 'h1' }}
            title={<>
                Заявки на услуги
                <NavLink variant="caption" route={[['page', 'request'], ['id']]}>Все <ChevronRight fontSize="small" /></NavLink>
                <NavButton size="small" sx={{ ml: 'auto' }} route={[['page', 'request'], ['id', 'new']]} variant="contained" color="info">
                    <AddRounded />
                </NavButton>
            </>} />
        <CardContent sx={{ display: 'flex', gap: 4, overflow: 'hidden' }}>
            <Box sx={{ bgcolor: 'info.light', p: 2, borderRadius: 1, position: 'relative' }}>
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    mt: 2,
                    left: 0,
                    height: 200,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 2
                }}>
                    <Typography variant="h1">{numberFormat(312)}</Typography>
                    <Typography variant="caption">заявки за месяц</Typography>
                </Box>

                {d ? <PieChart width={200} height={200} series={[
                    {
                        data: d,
                        innerRadius: 68,
                        outerRadius: 85,
                        paddingAngle: 5,
                        cornerRadius: 8,
                        cx: 95
                    }]} slotProps={{ noDataOverlay:<></>, legend: { hidden: true } }} /> : undefined}

                <table style={{ margin: 'auto' }}>
                    {d?.map(x => <tr>
                        <td><div style={{ backgroundColor: x.color, width: '1.25em', height: '.5em', borderRadius: '.25em' }} /></td>
                        <td><Typography variant="h3" px={.5}>{numberFormat(x.value)}</Typography></td>
                        <td><Typography component="div" variant="caption">{x.label as string}</Typography></td>
                    </tr>)}
                </table>
            </Box>

            <DataTable<Request> typeId="Request"
                columns={epCollumns}
                dataParser={requestParse}
                onAfterLoad={(d, r) => setData(d.reduce((a, b) => {
                    let i = a.find(x => x.id == b.Status);
                    if (!i) {
                        i = { id: b.Status as PieItemId, label: requestStatuses[b.Status!] || defaultRequestStatusTitle, value: 0, color: requestStatusColors[b.Status!] || '#FFB041' };
                        a.push(i);
                    }

                    i.value++;

                    return a;
                }, [] as PieValueType[]))}
                cmd={`RequestList?query=${JSON.stringify({
                    Sort: { Property: 'CreateDate', Direction: 1 },
                    Filters: [{ Property: 'CreateDate', Operator: 5, Value: new Date(now.getFullYear(), now.getMonth(), 1) }]
                })}`}
                showFilter={false}
                onSelectionChange={x => x[0] && route.setState([['page', 'request'], ['id', x[0]]])} />
        </CardContent>
    </Card>;
}

export function RequestWidget() {
    const ism = useIsMobile();
    return ism ? <MobileWidget /> : <DesktopWidget />;
}