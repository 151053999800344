import { Autocomplete, Box, Button, Paper, SxProps, TextField, Theme } from "@mui/material";
import React from "react";
import { AbstractTypedDataForm } from "../../../components/data/DataForm";
import { FormNumberInput, FormTextInput } from "../../../components/form/TextInput";
import { Service } from "../../../models/Types";
import { isMobile } from "../../../utils";
import Proxy from "../../../models/Proxy";

export function serviceParse(d?: any) {
    const dto = (d || {}) as Service;
    dto.Categories || (dto.Categories = []);
    return dto;
}

export class ServiceForm extends AbstractTypedDataForm<Service, {}, { cats: string[] }> {
    private ki = 0;

    componentDidMount() {
        super.componentDidMount();

        Proxy.get('ServiceCategories').then(x => this.setState({ cats: x.result }));
    }

    protected getTypeName(): React.ReactNode {
        return 'Услуга';
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        return {
            ...this.props.sx,
            flexDirection: 'row',
            alignItems: 'flex-start'
        };
    }

    buildButtons() {
        return [];
    }

    protected getTypeId(): string {
        return 'Service';
    }

    protected createEntity() {
        return serviceParse();
    }

    protected buildItems(entity?: Service | undefined): React.ReactNode {
        return <>
            <Paper sx={isMobile() ? { flex: 1 } : { minWidth: 500 }}>
                <FormTextInput key={`Name-${this.ki}`} required={true} label="Наименование" name="Name" form={this} entity={entity} />
                <FormTextInput key={`Unit-${this.ki}`} required={true} label="Единицы измерения" name="Unit" form={this} entity={entity} />
                <FormNumberInput key={`Price-${this.ki}`} required={true} label="Стоимость за единицу" name="Price" form={this} entity={entity} />
                <Autocomplete multiple
                    options={this.state?.cats || []} defaultValue={entity?.Categories} freeSolo
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Категории" name="Categories" />}
                    onChange={(e: any, v) => this.onChange({
                        target: {
                            name: 'Categories',
                            value: v
                        }
                    })}
                />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    <Button variant="contained" type="submit">Сохранить</Button>
                </Box>
            </Paper>
        </>;
    }
}