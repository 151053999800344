import { AddRounded, ChevronRight } from "@mui/icons-material";
import { Card, CardHeader, Theme, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { columns } from ".";
import route from "../../Router";
import NavLink, { NavButton } from "../../components/NavLink";
import AccentPaper from "../../components/Paper";
import DataTable, { BaseDataTable, ColumnData, Renderer } from "../../components/data/DataTable";
import car from "../../icons/car.png";
import { useIsMobile } from "../../models/Hooks";
import { EntryPass } from "../../models/Types";
import { entryPassParse } from "./EntryPassForm";

function MobileWidget() {
    return <AccentPaper sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'space-between' }}>
        <NavLink variant="h2" sx={{ fontWeight: 600, position: 'relative', textDecoration: 'none' }}>
            Оформить гостевой пропуск
            <img src={car} style={{ maxHeight: '180%', position: 'absolute', right: 0, top: '-.5em' }} />
        </NavLink>
    </AccentPaper>
}

const epCollumns = [
    { name: 'CarNumber', variant: 'h3' },
    { name: 'CarModel', variant: 'caption' },
    { name: 'Color', variant: 'caption', color: 'textSecondary' },
    { name: 'Expire', variant: 'h4' }
].map(n => {
    const col = { ...columns.find(x => x.name == n.name) } as ColumnData<EntryPass>;
    if (col.routerLink) {
        col.routerParams = p => {
            p.push(['page', 'entrypass']);
            return p;
        }
    }
    const renderer = col.renderer || ((x: any, o: EntryPass, th: Theme, ta: BaseDataTable<EntryPass>, d?: Renderer<EntryPass>) => d ? d(x, o, th, ta, d) : x);
    col.renderer = (x, o, th, ta, d) => <Typography variant={n.variant as Variant} color={n.color}>{renderer(x, o, th, ta, d)}</Typography>
    return col;
});


function DesktopWidget() {
    return <Card sx={{ flex: 1 }}>
        <CardHeader
            titleTypographyProps={{ display: 'flex', variant: 'h1' }}
            title={<>
                Выданные пропуска
                <NavLink variant="caption" route={[['page', 'entrypass'], ['id']]}>Все <ChevronRight fontSize="small" /></NavLink>
                <NavButton size="small" sx={{ ml: 'auto' }} route={[['page', 'entrypass'], ['id', 'new']]} variant="contained" color="info">
                    <AddRounded />
                </NavButton>
            </>} />
        <DataTable<EntryPass> typeId="EntryPass"
            columns={epCollumns}
            dataParser={entryPassParse}
            cmd={'EntryPassList'}
            showFilter={false}
            onSelectionChange={x => x[0] && route.setState([['page','entrypass'],['id', x[0]]])} />
    </Card>;
}

export function EntryPassWidget() {
    const ism = useIsMobile();
    return ism ? <MobileWidget /> : <DesktopWidget />;
}