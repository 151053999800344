import { useEffect, useMemo, useState } from "react";
import route, { UrlRoute } from "../Router";
import { getUser, listenUser } from "./Identity";
import Observer from "./Observer";
import { getTenant, listenTenant } from "./Tenant";
import Proxy from "./Proxy";
import CachedStore from "./CachedStore";
import { isMobile } from "../utils";

export function useTenant() {
    const [tenant, setTenant] = useState(getTenant);
    useEffect(() => listenTenant(setTenant));
    return tenant;
}

export function useTenantId() {
    const [tenant, setTenant] = useState(getTenant);
    useEffect(() => listenTenant(setTenant));
    return tenant?.Id;
}

export function useUser() {
    const [user, setUser] = useState(getUser);
    useEffect(() => listenUser(setUser));
    return user;
}

const ismo = new Observer();
var ism = isMobile();
window.addEventListener('resize', () => {
    var nim = isMobile();
    if (ism !== nim) {
        ismo.fire('resize', [ism = nim]);
    }
});
export const listenIsMobile = (fn: ((ism: boolean) => any)) => ismo.listen('resize', fn);
export function useIsMobile() {
    const [im, setIm] = useState(ism);
    useEffect(() => listenIsMobile(ism => setIm(ism)));
    return im;
}

export function useRoute(onChange?: (path: string) => any): [UrlRoute, string] {
    const [path, setPath] = useState(route.getPath);
    useEffect(() => route.listen(r => {
        const path = r.getPath();
        setPath(path);
        onChange && onChange(path);
    }));
    return [route, path];
}

const observers: { [name: string]: Observer } = {};
export function useObserver<TParams>(name: string, params?: TParams): [TParams | undefined, (params?: TParams) => void] {
    if (!observers[name]) {
        observers[name] = new Observer();
    }

    const [p, sp] = useState(params);
    useEffect(() => observers[name].listen('l', (p?: TParams) => sp(p)));
    return [p, p => observers[name].fire('l', [p])];
}

interface ListResult<T> {
    Data: T[]
    TotalCount: number
}
export function useRemoteList<T>(url: string, callback?: DataLoadCallback<ListResult<T>> | null, defaultData?: ListResult<T>)
    : [T[] | null, (loadUrl?: string) => Promise<ListResult<T>>] {
    const [data, load] = useRemoteData<ListResult<T>>(url, callback, defaultData);
    const list = useMemo(() => data?.Data || null, [data]);
    return [list, load];
}

export type DataLoadCallback<T> = (data: T) => T;
export function useRemoteData<T>(url: string, callback?: DataLoadCallback<T> | null, defaultData?: T)
    : [T | null, (loadUrl?: string) => Promise<T>] {
    const [data, setData] = useState<[string, T] | undefined>(defaultData ? [url, defaultData] : undefined);
    const [loading, setLoading] = useState<Promise<T>>();

    function load(loadUrl?: string) {
        if (loading) {
            return loading;
        }

        if (!loadUrl) {
            loadUrl = url;
        }

        var req = Proxy.get(loadUrl).then(x => {
            x = x.hasOwnProperty('result') ? x.result : x;
            if (x.$type && x.$values) {
                x = x.$values;
            }

            if (callback) {
                x = callback(x as T);
            }

            setData([loadUrl!, x as T]);
            setLoading(undefined);

            return x as T;
        });

        setLoading(req);

        return req;
    }

    useEffect(() => {
        if (!data || data[0] !== url) {
            load();
        }
    }, [url, callback]);

    let d = data && data[1];
    return [((d === 0) || (d === false)) ? d : (d || null), load];
}

export function useCachedStore<T>(store: CachedStore<T>): [T[] | undefined, typeof store] {
    const [data, setData] = useState(store.data);

    useEffect(() => store.onLoad(d => setData(d)));

    return [data, store];
}