import { Box, Button, Paper } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput, TextInput } from "../../components/form/TextInput";
import { EntryPass } from "../../models/Types";
import { isMobile } from "../../utils";
import HouseCombo from "../houses/HouseCombo";

export function entryPassParse(d?: any) {
    const dto = (d || {}) as EntryPass;
    d?.Expire && (dto.Expire = new Date(d.Expire));
    return dto;
}

export class EntryPassForm extends AbstractTypedDataForm<EntryPass, {}, { houseId?: number }> {
    protected getTypeId(): string {
        return 'EntryPass';
    }
    protected getTypeName(): React.ReactNode {
        return 'Пропуск';
    }

    buildButtons() {
        return [];
    }

    protected buildItems(entity?: EntryPass | undefined): React.ReactNode {
        return <>
            <Paper sx={isMobile() ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { minWidth: 500 }} style={{ overflow: 'auto' }}>
                {/*<Box display="flex" flexDirection="column" gap={2} overflow="auto">*/}
                <TextInput
                    value={entity?.HouseId}
                    required={true}
                    label="Дом"
                    name="HouseId"
                    onChange={this.onChange}
                    component={(onChange, error) => <HouseCombo
                        name="HouseId"
                        error={!!error}
                        selectFirstOnLoad={entity && !entity.Id}
                        value={entity?.HouseId}
                        onChange={onChange} />} />

                <DatePicker label="Активен до" value={moment(entity?.Expire)} onChange={v => v?.toDate() && (entity!.Expire = v?.toDate())} />

                <FormTextInput label="Рег. номер" name="CarNumber" form={this} entity={entity} required={true} />
                <FormTextInput label="Модель т/с" name="CarModel" form={this} entity={entity} />
                <FormTextInput label="Цвет" name="Color" form={this} entity={entity} />

                <FormTextInput label="Название пропуска" name="Name" form={this} entity={entity} />

                <div style={{ flex: 1 }} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    {<Button variant="contained" type="submit">Сохранить</Button>}
                </Box>
            </Paper>
        </>;
    }
}