import { Box, Link, Typography } from '@mui/material';
import { ReactNode } from 'react';
import NavLink from '../../components/NavLink';

interface IVersionInfo {
    version: string,
    desc: ReactNode[]
}

export const versions: IVersionInfo[] = [{
    version: '0.3.250131',
    desc: [
        <>Добавлена темизация в <NavLink route={[['page', 'project/project'], ['id']]}>форме редактирования ЖК</NavLink></>
    ]
}, {
    version: '0.2.250123',
    desc: [
        <>Добавлен справочник <NavLink route={[['page', 'project/emploees'], ['id']]}>Сотрудников</NavLink></>
    ]
}, {
    version: '0.2.250122',
    desc: [
        <>Добавлен справочник <NavLink route={[['page', 'project/positions'], ['id']]}>Должностей</NavLink></>
    ]
}, {
    version: '0.1.241226',
    desc: [
        'Добавлено автообновление выпадающих списков для выбора из справочника при изменении справочника'
    ]
}];

export default function VersionView() {
    return <Box>
        <Typography variant="h4">История версий</Typography>

        {versions.map(v => <Box component="p">
            <Typography variant="h5" component={Link} href={'#' + v.version}>#{v.version}</Typography>
            <ul>
                {v.desc.map(d => <li>{d}</li>)}
            </ul>
        </Box>) }
    </Box>;
}