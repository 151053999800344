import { AddRounded, AutoFixHighRounded, CheckCircleOutlineRounded, CheckRounded, CloseRounded, ConstructionRounded, HighlightOffRounded, HomeRepairServiceRounded, Receipt } from '@mui/icons-material';
import { Button, Toolbar, Typography, alpha } from '@mui/material';
import { ReactNode, useRef } from 'react';
import { ListApiRef } from '../../components/data/ApiRef';
import DataTable, { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import TablePaper from '../../components/form/TablePaper';
import { RequestStatus, defaultRequestStatusTitle, requestStatuses } from '../../models/Enums';
import Proxy from '../../models/Proxy';
import { Bill, ProvidedService, Request } from '../../models/Types';
import { getTheme } from '../../theme';
import { psBillCollumns } from '../bill';
import HouseCombo, { houseStore } from '../houses/HouseCombo';
import InhabitantCombo, { inhabitantStore } from '../inhabitants/InhabitantCombo';
import ServiceCombo, { serviceStore, serviceTextRenderer } from '../project/service/ServiceCombo';
import { columns as psCollumns } from '../providedService';
import { RequestForm, requestParse } from './RequestForm';


export const requestStatusIcons: { [status: keyof RequestStatus]: () => ReactNode } = {
    [RequestStatus.Implementing]: () => <ConstructionRounded />,
    [RequestStatus.Completed]: () => <CheckRounded />,
    [RequestStatus.Canceled]: () => <CloseRounded />,
    [RequestStatus.Rejected]: () => <CloseRounded />
}
export const requestStatusMobileIcons: { [status: keyof RequestStatus]: () => ReactNode } = {
    [RequestStatus.Completed]: () => <CheckCircleOutlineRounded color="success" fontSize="small" />,
    [RequestStatus.Canceled]: () => <HighlightOffRounded color="error" fontSize="small" />,
    [RequestStatus.Rejected]: () => <CheckCircleOutlineRounded color="success" fontSize="small" />
}
export const requestStatusColors: { [status: keyof RequestStatus]: string } = {
    [RequestStatus.Analyze]: alpha('#FFB041', 0.5),
    [RequestStatus.Planing]: alpha('#5092FF', 0.5),
    [RequestStatus.Implementing]: '#5092FF',
    [RequestStatus.Rejected]: '#FF8684',
    [RequestStatus.Completed]: '#27C397',
    [RequestStatus.Canceled]: getTheme().palette.text.secondary
}
export const defaultRequestStatusColor = '#FFF0C8';
export const defaultRequestStatusLightColor = alpha('#FFF0C8', 0.5);
export const requestStatusLightColors: { [status: keyof RequestStatus]: string } = {}
for (var i in requestStatusColors) {
    requestStatusLightColors[i] = alpha(requestStatusColors[i], .3)
}

export const requestColumns: ColumnData<Request>[] = [{
    label: 'Наименование',
    name: 'Name',
    routerLink: true
}, {
    label: 'Услуги',
    name: 'ServiceIds',
    type: 'array',
    store: serviceStore,
    filter: (onChange) => <ServiceCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => (val as number[])?.map(v => serviceStore.data?.filter(x => x.Id == v).map(serviceTextRenderer)).join(', ')
}, {
    label: 'Адреса',
    name: 'HouseIds',
    type: 'array',
    store: houseStore,
    filter: (onChange) => <HouseCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => (val as number[])?.map(v => houseStore.data?.filter(x => x.Id == v).map(x => x.Value)).join(', ')
}, {
    label: 'Дата',
    name: 'CreateDate',
    filter: true,
    type: 'date',
}, {
    label: 'Статус',
    name: 'Status',
    filter: true,
    renderer: (x: RequestStatus) => <Typography 
        variant="overline"
        sx={{
            textTransform: 'none',
            backgroundColor: requestStatusLightColors[x] || defaultRequestStatusLightColor,
            px: 1, py: .75,
            borderRadius: 1
        }}>{requestStatuses[x] || defaultRequestStatusTitle}</Typography>
}, {
    label: 'Заявители',
    name: 'ApplicantId',
    store: inhabitantStore,
    filter: (onChange) => <InhabitantCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => inhabitantStore.data?.find(x => x.Id == val)?.Value
}];

const recPsCollumns = psCollumns.map(x => x.routerLink ? { ...x, routerLink: false } : x);

function Services({ id }: { id: number }) {
    const ref = useRef<ListApiRef<ProvidedService>>();

    function fillServices() {
        Proxy.post('ProvidedServiceFillForRequest', id).then(x => x && ref.current?.load());
    }

    return <TablePaper>
        <Toolbar>
            <Button startIcon={<AutoFixHighRounded />} onClick={fillServices}>Заполнить услуги</Button>
            <Button startIcon={<AddRounded />} color="primary" sx={{ ml: 'auto' }}>Добавить</Button>
        </Toolbar>
        <DataTable<ProvidedService> typeId="ProvidedService" apiRef={x => ref.current = x} columns={recPsCollumns} cmd={'ProvidedServiceForRequest?id=' + id} />
    </TablePaper>
}

export default function RequestView() {
    return <DataView<Request>
        title="Заявка"
        titleMultiple="Заявки"
        typeId="Request"
        form={[{
            title: 'Основное',
            element: (id, apiRef) => <RequestForm key="main" id={id} apiRef={apiRef} />
        }, {
            title: 'Услуги',
            icon: <HomeRepairServiceRounded />,
            element: id => <Services id={id} />
        }, {
            title: 'Начисления',
            icon: <Receipt />,
            element: (id, apiRef) => <TablePaper sx={{ overflow: 'hidden', p: 0 }} >
                <Toolbar>
                    <Button startIcon={<AutoFixHighRounded />}>Сформировать начисления</Button>
                </Toolbar>
                <DataTable<Bill> typeId="Bill" columns={psBillCollumns} cmd={'BillForRequest?id=' + id} />
            </TablePaper>
        }]}
        tableProps={{ columns: requestColumns, dataParser: requestParse }} />;
}