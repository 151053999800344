import { PaletteColorOptions, Theme, alpha, createTheme as createMuiTheme } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import { listenIsMobile } from './models/Hooks';
import { getTenant, listenTenant } from './models/Tenant';
import { isMobile } from './utils';

var theme: Theme;

export const primary = '#0260F9';
export const getTheme = () => theme;

const defaultPalette = createPalette({});

declare module "@mui/material/styles" {
    interface TypographyVariants {
        menu: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        menu?: React.CSSProperties;
    }

    interface PaletteOptions {
        button?: PaletteColorOptions;
    }
}

declare module '@mui/material' {
    interface ButtonPropsColorOverrides {
        button: true
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        menu: true;
    }
}

function createTheme() {
    const tenant = getTenant();

    theme = createMuiTheme({
        cssVariables: true,
        palette: {
            primary: {
                main: tenant?.PrimaryColor || primary
            },
            info: {
                contrastText: primary,
                main: '#D3E9FF',
                light: alpha('#D3E9FF', 0.5),
                dark: alpha('#D3E9FF', 0.9)
            },
            secondary: {
                main: '#1C252E',
                light: '#FBF8F3'
            },
            text: {
                disabled: '#7B818F'
            }
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#FFF'
                    }
                }
            },
            MuiToolbar: {
                defaultProps: {
                    color: 'transparent'
                },
                styleOverrides: isMobile() ? {
                    root: {
                        minHeight: 0
                    }
                } : undefined
            },
            MuiPaper: {
                styleOverrides: {
                    elevation1: {
                        boxShadow: 'rgba(145, 158, 171, 0.16) 0px 1px 2px 0px',
                        margin: 2
                    },
                    elevation2: {
                        boxShadow: '0px 3px 10px 0px #3A5E811A'
                    },
                    rounded: {
                        borderRadius: isMobile() ? '.5rem' : '.75rem'
                    },
                    root: {
                        '& > .MuiFormControl-root:first-child': {
                            marginTop: '1rem'
                        }
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        fontWeight: 500,
                        borderRadius: 0,
                        '&.Mui-selected': {
                            color: "secondary",
                            fontWeight: 600
                        }
                    }
                }
            },
            MuiCardHeader: {
                defaultProps: {
                    titleTypographyProps: {
                        variant: 'h1'
                    }
                }
            },
            MuiSwitch: {
                defaultProps: {
                    color: 'primary'
                }
            },
            MuiCheckbox: {
                defaultProps: {
                    color: 'primary'
                }
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        textTransform: 'none'
                    }
                }
            },
            MuiToggleButtonGroup: {
                defaultProps: {
                    size: "small"
                }
            },
            MuiToggleButton: {
                defaultProps: {
                    size: "small"
                }
            },
            MuiOutlinedInput: {/**
            styleOverrides: {
                root: {
                    overflow: "hidden",
                    backgroundColor: '#fff'
                },
                input: {
                    backgroundColor: '#fff',
                    //fontSize: '1.1735rem'
                }
            },/**/
                defaultProps: {
                    size: "small",
                    fullWidth: true,
                    minRows: 2,
                    maxRows: 6
                }
            },
            MuiTextField: {
                defaultProps: {
                    size: 'small'
                }
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        marginLeft: 0
                    }
                }
            },
            MuiFormLabel: {/**
            styleOverrides: {
                root: {
                    marginTop: 8,
                    fontWeight: 500,
                    fontSize: '1.0745rem'
                }
            }/**/
            },
            MuiFormControl: {
                defaultProps: {
                    fullWidth: true
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        boxShadow: 'none',
                        ...(isMobile() ? {
                            fontSize: 10,
                            lineHeight: 1.4,
                            padding: '10px'
                        } : null)
                    },
                    outlined: {
                        backgroundColor: '#fff'
                    }
                }
            }
        },
        typography: {
            ...({
                h1: { fontSize: 24, lineHeight: 1.2, fontWeight: 700 },
                h2: { fontSize: 16, lineHeight: 1.36625, fontWeight: 700 },
                h3: { fontSize: 14, lineHeight: 1, fontWeight: 600 },
                h4: { fontSize: 12, lineHeight: 1, fontWeight: 600 },
                h5: { fontSize: 10, lineHeight: 1, fontWeight: 500 },
                menu: { fontSize: 16, lineHeight: 1.36625, fontWeight: 400 },
                body1: { fontSize: 18, lineHeight: 1.4, fontWeight: 400 },
                body2: { fontSize: 14, lineHeight: 1.2, fontWeight: 500 },
                caption: { fontSize: 12, lineHeight: 1, fontWeight: 400 },
                subtitle2: { fontSize: 10, lineHeight: 1, fontWeight: 400 },
                overline: { fontSize: 8, lineHeight: 1, fontWeight: 400 }
            }),
            fontFamily: [
                'Manrope',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(',')
        }
    });
}

createTheme();

listenTenant(createTheme);
listenIsMobile(createTheme);